<template>
  <div class="w-full h-screen reserves">
    <NavbarComponent />
    <div class="flex items-center justify-start w-full h-16 px-6 bg-pronto-500 md:px-16 xl:px-12">
      <p class="text-xl font-semibold text-white md:text-2xl">Historial de reservas</p>
    </div>

    <div class="p-6 pb-0">
      Reservas
      <span v-if="filter == 'antiguos'">finalizadas</span>
      <span v-if="filter == 'actuales'">confirmadas</span>
      realizadas por el
      <span v-if="filterAdmin == 'mis_reservas'">usuario</span>
      <span v-else>cliente</span>
    </div>
    <div class="w-full mt-8" v-if="loaded">

      <div class="flex justify-between w-full mb-2">

        <div class="w-64">
          <SelectComponent label="Filtrar por estado de reserva" :options="filterOptions" v-model="filter" />
        </div>
        <div class="w-64" v-if="user_admin">
          <SelectComponent label="Filtrar por propiedad" :options="filterAdminOptions" v-model="filterAdmin" />
        </div>
      </div>
      <TableComponent :data="reserves" :keys="keys" @delete-reserve="deleteReserve" />
      <PaginationComponent :data="pageData" :on-click="selectPage" />
    </div>
    <!-- <div class="w-full" v-else>
      <span class="text-gray-700">Cargando reservas</span>
    </div> -->

    <!-- Cancel reserve modal -->
    <!-- TODO improve responsivity -->
    <div class="fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-50 xl:absolute" v-if="deleteModal.show"></div>
    <div class="fixed inset-0 z-40 grid w-full h-full xl:absolute" v-if="deleteModal.show" @click.prevent="hideModal()">
      <div class="py-6 m-auto bg-white border border-gray-200 rounded shadow-lg sm:w-full sm:mx-6 w-calendarTablet"
        @click.prevent="hideModal()">
        <div class="sm:flex sm:items-start">

          <div class="px-6">
            <p class="font-semibold text-gray-700 text-md">¿Está seguro de anular la reserva?</p>
          </div>

          <div class="flex flex-row justify-center w-full px-6 mt-5">

            <div class="w-1/3 mr-10">
              <button type="button"
                class="justify-center w-full px-4 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="hideModal()">
                Cancelar
              </button>
            </div>

            <div class="w-1/3 ">
              <button type="button"
                class="justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="confirmDeleteReserve">
                Anular
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- End: Cancel reserve modal -->

  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue'
import TableComponent from '@/components/TableComponent.vue'
import SelectComponent from '@/components/SelectComponent.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    NavbarComponent,
    TableComponent,
    SelectComponent,
    PaginationComponent
  },

  data() {
    return {
      filterOptions: [
        { label: 'Todos', value: 'todos' },
        { label: 'Finalizada', value: 'antiguos' },
        { label: 'Confirmada', value: 'actuales' },
      ],
      filterAdminOptions: [
        { label: 'Reservas cliente', value: 'todos' },
        { label: 'Mis reservas', value: 'mis_reservas' },
      ],
      deleteModal: {
        show: false,
        item: false,
      },
      filter: 'todos',
      filterAdmin: 'todos',
      sort: 'centro',
      pageData: null,
    }
  },

  created() {
    this.loadReserves({ page: 1, filter: this.filter, filterAdmin: this.filterAdmin })
      .then(res => this.pageData = res)
  },

  methods: {
    ...mapActions({
      loadReserves: "myReserves/loadMyReserves",
      deleteReservation: 'reserve/deleteReservation',
    }),

    selectPage(page) {
      this.loadReserves({ page, filter: this.filter, filterAdmin: this.filterAdmin })
    },

    deleteReserve(product) {
      this.deleteModal.show = true
      this.deleteModal.item = product
    },

    async confirmDeleteReserve() {
      await this.deleteReservation(this.deleteModal.item.id)
      this.loadReserves({ page: this.pageData.page, filter: this.filter, filterAdmin: this.filterAdmin }).then(res => this.pageData = res)
      this.hideModal()
    },

    hideModal() {
      this.deleteModal.show = false
      this.deleteModal.id = null
    }
  },

  computed: {
    ...mapGetters({
      reserves: "myReserves/reserves",
      keys: "myReserves/keys",
      loaded: "myReserves/loaded",
      user_admin: "auth/user_admin",
    })
  },

  watch: {
    filter() {
      this.loadReserves({ page: 1, filter: this.filter, filterAdmin: this.filterAdmin })
        .then(res => this.pageData = res)
    },
    filterAdmin() {
      this.loadReserves({ page: 1, filter: this.filter, filterAdmin: this.filterAdmin })
        .then(res => this.pageData = res)
    },
  }
}
</script>
