<template>
  <div class="w-full">
    <!-- Select Space Modal -->
    <div class="fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-50" v-if="modal.show"></div>
    <div class="fixed inset-0 z-40 grid w-full h-full" v-if="modal.show" @click.prevent="hideModal(1)">

        <div class="pb-6 m-auto overflow-y-scroll bg-white border border-gray-200 rounded-b shadow-lg w-calendarTablet max-h-96" @click.prevent="hideModal(2)">
        <!-- <div class="h-12 mx-auto -mt-10 text-gray-700 bg-gray-300 border border-gray-200 rounded-t w-calendarTablet">
          <p class="">Selecciona un estacionamiento</p>
        </div> -->
        <div class="relative flex items-center w-full px-6 mt-6">
          <div class="absolute w-5 h-5 ml-3">
            <IconComponent prefix="search_gray" dark/>
          </div>
          <input class="flex items-center w-full h-10 pl-10 pr-4 font-light text-gray-800 border border-gray-200 rounded bg-gray-50 focus:outline-none" v-model="query" type="text" placeholder="Ingrese un nombre de espacio">
        </div>
        <div class="mt-4">
          <div class="w-full" v-for="(queryItem, queryIndex) in queryItems(modal.index)" :key="'queryItem-'+modal.index+'-'+queryIndex" @click="selectSpaceSelectedFromModal(modal.index, queryIndex)">
            <div class="flex items-center justify-between w-full h-10 px-6">
              <p class="text-sm text-gray-700 select-none">{{queryItem.name}}</p>
              <div class="w-3 h-3">
                <span class="flex w-3 h-3" v-if="queryIndex == selectedSpaces[modal.index]">
                  <span class="absolute inline-flex w-3 h-3 rounded-full opacity-75 bg-prontogreen-200 animate-ping"></span>
                  <span class="relative inline-flex w-3 h-3 rounded-full bg-prontogreen-400"></span>
                </span>
              </div>
            </div>
            <div class="w-full h-px bg-gray-200" v-if="queryIndex < queryItems(modal.index).length - 1"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Select Space Modal -->

    <!-- Coffee Break Modal -->
    <div class="fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-50" v-if="coffee.show"></div>
    <div class="fixed inset-0 z-40 grid h-full" v-if="coffee.show" @click.prevent="hideCoffee(1)">
      <div class="max-w-lg m-auto overflow-y-scroll">
        <div class="py-6 mx-4 overflow-y-scroll bg-white border border-gray-200 rounded shadow-lg"  @click.prevent="hideCoffee(2)">
          <div class="px-6">
            <p class="text-lg font-semibold text-gray-700">Horarios para el coffee-break</p>
            <p v-if="peopleCoffee" class="font-semibold text-gray-500 text-md">
              {{ peopleCoffee }} {{ peopleCoffee == 1 ? 'persona' : 'personas' }}
            </p>
          </div>
          <div class="grid w-full grid-cols-2 gap-4 px-6 mt-4 md:grid-cols-3 lg:grid-cols-4">
            <button v-for="(schedule, index) in groupCoffeeSchedule" :key="'coffee-selection'+index" @click="changeTimeCoffeeBreak(index)"
            class="px-3 py-1 mb-2 mr-2 border border-gray-200 rounded focus:outline-none " v-bind:class="{'bg-pronto-300 border-white text-white font-semibold': coffee.selected[index]}">
              <span class="text-sm">{{schedule.start_time}}-{{schedule.end_time}}</span>
            </button>
          </div>

          <div class="flex flex-wrap items-center justify-around w-full px-6 mt-4">
            <button type="button" class="justify-center px-4 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click.prevent="hideCoffee(1)">
              Cancelar
            </button>
            <button class="px-4 py-2 text-base font-medium text-white rounded-md bg-pronto-400 focus:outline-none hover:bg-pronto-500 sm:mt-0 sm:w-auto sm:text-sm" @click.prevent="saveTimeCoffeeBreak()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Coffee Break Modal -->

    <div class="w-full" v-if="loaded">
      <div class="mt-10 md:flex md:justify-start md:flex-col md:items-center">

        <!-- sm -->
        <div class="w-full mb-8 border border-gray-200 rounded md:hidden" v-for="(spaceSchedule, index) in spaceSchedules" :key="'spaceSc-'+index">
          <div class="w-full h-40 rounded-t" v-if="!isDropdownOpen[index]">
            <img class="object-cover w-full h-full" src="@/assets/images/home.jpeg">
          </div>
          <div class="flex items-center justify-between w-full h-12 px-4 text-white bg-pronto-300" @click.prevent="toogleDropdown(index)">
            <div class="flex items-center">
              <IconComponent :prefix="spaceSchedule[selectedSpaces[index]].prefix" white/>
              <p class="ml-5 ">{{spaceSchedule[selectedSpaces[index]].name}}</p>
            </div>
            <div class="w-5 h-5 focus:outline-none">
              <IconComponent v-if="!isDropdownOpen[index]" prefix="chevron-down" white/>
              <IconComponent v-else prefix="x_gray" white/>
            </div>
          </div>
          <div class="p-4" v-if="!isDropdownOpen[index]">
            <div class="flex flex-wrap w-full gap-x-2">
              <div class="flex items-center justify-center h-8 mb-2 font-light text-gray-700 border border-gray-200 rounded-sm cursor-pointer w-14 md:w-18" v-bind:class="{'bg-pronto-300 border-none': item.reserved, 'bg-pronto-100': item.available && getSafe2(highlights, [index, 'schedules', itemIndex])}"
              v-for="(item, itemIndex) in spaceSchedule[selectedSpaces[index]].schedule" :key="'scheduleItem'+itemIndex" @mouseover="handleMouseoverSchedule(index,itemIndex)" @mouseleave="handleMouseleaveSchedule(index,itemIndex)" @click.prevent="selectSchedule(index, selectedSpaces[index], itemIndex)">
                <p class="flex items-center justify-center h-8 select-none" v-bind:class="{'text-gray-200 cursor-not-allowed': !item.available, 'text-white': item.reserved}">{{item.time}}</p>
              </div>
            </div>
          </div>
          <div class="mt-4" v-else>
            <div class="relative flex items-center w-full px-4">
              <div class="absolute w-5 h-5 ml-3">
                <IconComponent prefix="chevron-down" dark/>
              </div>
              <input class="flex items-center w-full h-10 pl-10 pr-4 font-light text-gray-800 border border-gray-200 rounded bg-gray-50 focus:outline-none" v-model="query" type="text">
            </div>
            <div class="mt-4">
              <div class="w-full" v-for="(queryItem, queryIndex) in queryItems(index)" :key="'queryItem-'+modal.index+'-'+queryIndex" @click="selectSpaceSelected(index, queryIndex)">
                <div class="flex items-center justify-between w-full h-10 px-4">
                  <p class="text-sm text-gray-700">{{queryItem.name}}</p>
                  <div class="w-3 h-3">
                    <span class="flex w-3 h-3" v-if="queryIndex == selectedSpaces[index]">
                      <span class="absolute inline-flex w-3 h-3 rounded-full opacity-75 bg-prontogreen-200 animate-ping"></span>
                      <span class="relative inline-flex w-3 h-3 rounded-full bg-prontogreen-400"></span>
                    </span>
                  </div>
                </div>
                <div class="w-full h-px bg-gray-200" v-if="queryIndex < queryItems(index).length - 1"></div>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="canCoffee(index, spaceSchedule[selectedSpaces[index]].schedule)">
            <div class="flex items-center justify-between w-full h-16 px-4 bg-gray-100 border-t border-gray-200">
              <div class="flex items-center">
                <CheckboxComponent :enabled.sync="coffee.enabled[index]" @update:enabled="changeCoffeeBreak(index, $event)"/>
                <span class="ml-4 text-gray-700">Agregar coffee-break</span>
                <span class="ml-2 text-sm text-gray-500" v-if="canSelectCoffee(index)">({{coffeeCount[index]}} seleccionados)</span>
              </div>
              <button class="px-2 rounded bg-pronto-500 focus:outline-none"
              v-if="canSelectCoffee(index)"
              ><span class="text-sm font-medium text-white" @click.prevent="openCoffeeBreakModal(index)">Seleccionar</span></button>
            </div>
          </div>
        </div>

        <!-- md + -->

        <div class="hidden mb-8 border border-gray-200 rounded md:block" v-for="(spaceSchedule, index) in spaceSchedules" :key="'spaceScMD-'+index">
          <div class="flex flex-col bg-white">
            <div class="relative flex items-center justify-center px-4 py-3 overflow-hidden text-white border-b border-gray-200 cursor-pointer bg-pronto-300"  @click.prevent="toogleModal(index)">
              <div class="mr-4">
              <IconComponent :prefix="spaceSchedule[selectedSpaces[index]].prefix" white/>
              </div>
              <p class="font-medium ">
                {{spaceSchedule[selectedSpaces[index]].name}}
              </p>
              <div class="absolute w-5 h-5 top-4 right-4">
                <IconComponent prefix="chevron-down" white/>
              </div>
            </div>
            <div class="flex w-full bg-white">
              <div class="border-r border-gray-200 w-50 h-70">
                <img class="object-cover w-full h-full" src="@/assets/images/office.jpeg" alt="">
              </div>
              <div class="h-full">
                <div class="flex flex-wrap justify-between px-4 py-5 w-108">
                  <div class="flex items-center justify-center h-8 mb-2 font-light text-gray-700 border border-gray-200 rounded-sm cursor-pointer w-14 md:w-18" v-bind:class="{'bg-pronto-300 border-none': item.reserved, 'bg-pronto-100': item.available && getSafe2(highlights, [index, 'schedules', itemIndex])}"
                  v-for="(item, itemIndex) in spaceSchedule[selectedSpaces[index]].schedule" :key="'scheduleItem'+itemIndex" @mouseover="handleMouseoverSchedule(index,itemIndex)" @mouseleave="handleMouseleaveSchedule(index,itemIndex)" @click.prevent="selectSchedule(index, selectedSpaces[index], itemIndex)">
                    <p class="flex items-center justify-center h-8 select-none" v-bind:class="{'text-gray-200 cursor-not-allowed': !item.available, 'text-white': item.reserved}">{{item.time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="canCoffee(index, spaceSchedule[selectedSpaces[index]].schedule)">
            <div class="flex items-center justify-between w-full h-16 px-4 border-t border-gray-200 bg-gray-50">
              <div class="flex items-center">
                <CheckboxComponent :enabled.sync="coffee.enabled[index]" @update:enabled="changeCoffeeBreak(index, $event)"/>
                <span class="ml-4 text-gray-700">Agregar coffee-break</span>
                <span class="ml-2 text-sm text-gray-500" v-if="canSelectCoffee(index)">({{coffeeCount[index]}} seleccionados)</span>
              </div>
              <button class="px-2 rounded bg-pronto-500 focus:outline-none"
              v-if="canSelectCoffee(index)"
              ><span class="text-sm font-medium text-white" @click.prevent="openCoffeeBreakModal(index)">Seleccionar</span></button>
            </div>
          </div>
          <div class="w-full" v-if="getBlocksSchedules(index).length">
            <div class="flex items-center justify-around w-full border-t border-gray-200 bg-gray-50">
              <div class="flex items-center text-center">
                <span class="text-gray-700 text-xs">
                  Bloques seleccionados
                </span>
              </div>
              <div class="grid w-full gap-1 mt-4 grid-cols-4">
                <div v-for="(schedule, index2) in getBlocksSchedules(index)" :key="'blocks-schedules-'+index"
                class="text-center py-1 mb-2 border rounded focus:outline-none bg-pronto-300 border-white text-white font-semibold">
                  <span class="text-sm">{{schedule.start_time}}-{{schedule.end_time}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-else>
      <!-- loading -->
    </div>
  </div>
</template>

<script>

// TODO notify if a product is fully occupied
// TODO beauty coffee break selection *

import { mapActions, mapGetters } from 'vuex'
import IconComponent from '@/components/IconComponent.vue'
import CheckboxComponent from '@/components/CheckboxComponent.vue'
import {groupSchedules, getSafe2} from '@/helpers'

export default {
  data() {
    return {
      title: 'Estos son los horarios disponibles',
      isDropdownOpen: [false, false],
      query: '',
      modal: {
        selectedIndex: 0,
        show: false,
        hideModal: true,
      },
      coffee: {
        enabled: {},
        show: false,
        hideModal: true,
        index: 0,
        selected: [],
      },
      highlights: {}

    }
  },

  components: {
    IconComponent,
    CheckboxComponent,
  },

  async mounted() {
    await this.loadSpaceSchedules();

    this.highlights = Object.keys(this.spaceSchedules).reduce((total, index) => {
      let spaceSchedules = this.spaceSchedules[index][this.selectedSpaces[index]].schedule
      let schedules = spaceSchedules.map((_) => 0)
      total[index] = {
        schedules: schedules,
        lastSchedule: null,
      }
      return total
    }, {})

    this.coffee.enabled = Object.keys(this.spaceSchedules).reduce((total, index) => {
      total[index] = false
      return total
    }, {})

  },

  computed: {
    ...mapGetters({
      spaceSchedules: 'schedule/spaces',
      selectedSpaces: 'schedule/selected',
      isEnabledParking: 'reserve/isEnabledParking',
      coffeeBreak: 'schedule/coffeeBreak',
      loaded: 'schedule/loaded',
      step: 'reserve/step',
      day: 'reserve/humanizeDay',
      resSpaces: 'reserve/spaces',
      selectedMap: 'schedule/selectedMap',
    }),

    peopleCoffee() {
      if (!this.selectedMap) {
        return 0
      }
      let spaceIndex = this.selectedMap[this.coffee.index]
      let config = this.resSpaces.find(o => o.id == spaceIndex).configuration
      if (config.text != 'Cantidad') {
        return 0
      }
      return config.value
    },

    groupCoffeeSchedule() {
      if (!this.coffee.index) return  []
      let selectedSchedules = this.spaceSchedules[this.coffee.index][this.selectedSpaces[this.coffee.index]].schedule
      selectedSchedules = selectedSchedules.filter(s => s.reserved)
      return groupSchedules(selectedSchedules)
    },

    coffeeCount() {
      let coffeeCount = Object.keys(this.coffeeBreak).reduce((result, key) => {
        result[key] = this.coffeeBreak[key].filter(o => o).length;
        return result;
      }, {})

      return coffeeCount
    },
  },

  methods: {
    ...mapActions({
      loadSpaceSchedules: 'schedule/loadSpaceSchedules',
      setReserved: 'schedule/setReserved',
      setSelected: 'schedule/setSelected',
      setCoffeeBreak: 'schedule/setCoffeeBreak',
      clearReserved: 'schedule/clearReserved',
    }),
    getSafe2(data, paths) {
      let res = getSafe2(data, paths)

      return res
    },

    selectSchedule(space, spaceIndex, scheduleIndex) {
      this.selectHighlightSchedule(space, spaceIndex, scheduleIndex)
    },

    toogleDropdown(index) {
      this.$set(this.isDropdownOpen, index, !this.isDropdownOpen[index]);
    },

    toogleModal(index) {
      this.modal.index = index;
      this.modal.show = true;
    },

    hideModal(divIndex) {
      if (divIndex == 1) {
        if (this.modal.hideModal) {
          this.modal.index = 0;
          this.modal.show = false;
        }
        else {
          this.modal.hideModal = true;
        }
      }
      else {
        this.modal.hideModal = false;
      }
    },

    hideCoffee(divIndex) {
      if (divIndex == 1) {
        if (this.coffee.hideModal) {
          this.coffee.index = 0;
          this.coffee.show = false;
        }
        else {
          this.coffee.hideModal = true;
        }
      }
      else {
        this.coffee.hideModal = false;
      }
    },

    queryItems(index) {
      let result = {}
      this.spaceSchedules[index].forEach((value, key) => {
        let spaceIndex = this.selectedMap[index]
        let cond1 = !Object.keys(this.selectedSpaces)
          .filter(o => this.selectedMap[o] == spaceIndex)
          .map(o => this.selectedSpaces[o])
          .includes(key)

        let acceptedQuery = cond1 && new RegExp(".*" + this.query + ".*", "i").test(value.name)

        if (acceptedQuery) {
          result[key] = value
        }
      })
      return result;
    },

    selectSpaceSelected(index, spaceIndex) {
      this.$set(this.selectedSpaces, index, parseInt(spaceIndex))
      this.setSelected(this.selectedSpaces);
      this.toogleDropdown(index);
      if (!this.isDropdownOpen[index]) {
        this.query = ''
        this.clearReserved(index)
      }
    },

    selectSpaceSelectedFromModal(index, spaceIndex) {
      this.$set(this.selectedSpaces, index, parseInt(spaceIndex))
      this.setSelected(this.selectedSpaces);
      this.modal.index = 0;
      this.modal.show = false;
      if (!this.isDropdownOpen[index]) {
        this.query = ''
        this.clearReserved(index)
      }
    },

    canCoffee(spaceType, schedule) {
      // Only Conference Room
      if (spaceType != 8) {
        return false
      }
      let canCoffee = false
      schedule.forEach(element => {
        if (element.reserved) {
          canCoffee = true
        }
      })
      return canCoffee
    },

    openCoffeeBreakModal(index) {
      this.coffee.index = index
      this.coffee.show = true

      for (var i = 0; i < this.groupCoffeeSchedule.length; i++) {
        let v = this.coffeeBreak[index][i] !== undefined ? this.coffeeBreak[index][i] : false
        this.$set(this.coffee.selected, i, v)
      }
    },

    getCoffeeSchedule() {
      let selectedSchedules = this.spaceSchedules[this.coffee.index][this.selectedSpaces[this.coffee.index]].schedule
      selectedSchedules = selectedSchedules.filter(s => s.reserved)
      return groupSchedules(selectedSchedules)
    },

    changeCoffeeBreak(index, value) {
      if (value) {
        value = []
      } else {
        value = false
      }
      this.setCoffeeBreak({index, value})
    },

    canSelectCoffee(index) {
      return this.coffeeBreak.hasOwnProperty(index) && this.coffeeBreak[index] !== false
    },

    changeTimeCoffeeBreak(index) {
      this.$set(this.coffee.selected, index, !this.coffee.selected[index])
    },

    saveTimeCoffeeBreak() {
      this.setCoffeeBreak({index: this.coffee.index, value: this.coffee.selected.map(x => x)})
      this.hideCoffee(1)
    },
    handleMouseleaveSchedule(spaceIndex,index) {
      this.highlights[spaceIndex].schedules = this.highlights[spaceIndex].schedules.map((v,k) => 0)
    },
    handleMouseoverSchedule(spaceIndex, index) {
      let highlights = this.highlights[spaceIndex]
      let spaceSchedules = this.spaceSchedules[spaceIndex][this.selectedSpaces[spaceIndex]].schedule

      // clear
      let schedules = highlights.schedules.map((v,k) => 0)
      schedules[index] = 1

      if (highlights.lastSchedule !== null) {
        let start, end
        if (index < highlights.lastSchedule) {
          start = index
          end = highlights.lastSchedule
        } else {
          start = highlights.lastSchedule
          end = index
        }

        for (let i = start; i < end; i++) {
          if (!spaceSchedules[i].available) {
            highlights.schedules.map((v,k) => 0)
            schedules[index] = 1
            break
          }
          schedules[i] = 1
        }
      }
      highlights.schedules = schedules
    },

    selectHighlightSchedule(space, spaceIndex, scheduleIndex) {
      let highlights = this.highlights[space]
      let schedules = this.spaceSchedules[space][this.selectedSpaces[space]].schedule
      if (!schedules[scheduleIndex].available) {
        return
      }
      if (schedules[scheduleIndex].reserved) {
        // delete group
        let start = scheduleIndex, end = scheduleIndex
        while (start >= 0 && schedules[start].reserved) {
          start--
        }
        while (end < schedules.length && schedules[end].reserved) {
          end++
        }
        if (start+1 == end-1) {
          if (highlights.lastSchedule === null) {
            this.setReserved({space, spaceIndex, scheduleIndex});
          }
        } else {
          for (let i = start + 1; i < end; i++) {
            this.setReserved({space, spaceIndex, scheduleIndex: i});
          }
        }

        highlights.lastSchedule = null
        highlights.schedules = highlights.schedules.map((v,k) => 0)
        return
      }

      if (highlights.lastSchedule === null) {
        highlights.lastSchedule = scheduleIndex
        this.setReserved({space, spaceIndex, scheduleIndex})
        return
      } else {
        highlights.lastSchedule = null
      }

      highlights.schedules.forEach((value, i) => {
        if (value == 1 && !schedules[i].reserved) {
          this.setReserved({space, spaceIndex, scheduleIndex: i});
        }
      })
      highlights.schedules = highlights.schedules.map((v,k) => 0)
    },

    getBlocksSchedules(index) {
      let selectedSchedules = this.spaceSchedules[index][this.selectedSpaces[index]].schedule
      selectedSchedules = selectedSchedules.filter(s => s.reserved)
      return groupSchedules(selectedSchedules)
    },
  }
}
</script>
