import axios from '@/axios';
import { humanizeDate, groupToItemsSchedule } from '@/helpers';

export default {
  namespaced: true,

  state: {
    spaces: [],
    loaded: true,
    step: 0,
    day: new Date(),
    parkingId: 4,
    documentUrl: null,
    clientSpacesOnly: false,
    canChangeToClient: true,
    multilocacion_max: 0,
  },

  getters: {
    spaces(state) {
      return state.spaces;
    },

    loaded(state) {
      return state.loaded;
    },

    step(state) {
      return state.step;
    },

    day(state) {
      return state.day;
    },

    parkingId(state) {
      return state.parkingId;
    },

    documentUrl(state) {
      return state.documentUrl;
    },

    clientSpacesOnly(state) {
      return state.clientSpacesOnly;
    },

    canChangeToClient(state) {
      return state.canChangeToClient;
    },

    // custom getters

    enabledSpaces(state) {
      return state.spaces.filter((space) => space.enabled && space.id != state.parkingId).map(o => o['id'])
    },

    isEnabledParking(state) {
      let enabledParking = state.spaces.find((space) => space.id == state.parkingId)
      return !!enabledParking ? enabledParking.enabled : false
    },

    isEnabledSpaces(state) {
      let enabled = state.spaces.filter((space) => space.id != state.parkingId && space.enabled)
      return !!enabled ? !!enabled.length : false
    },

    humanizeDay(state) {
      return humanizeDate(state.day)
    },

    systemDay(state) {
      return state.day.getFullYear() + "-" + ('0'+ (state.day.getMonth()+1)).slice(-2) + "-" + ('0' + state.day.getDate()).slice(-2)
    },

    parkingCount(state) {
      return state.spaces ? state.spaces.find(o => o.id == state.parkingId).configuration.value : 0
    },

    centroMultilocacionId(state) {
      if (!state.spaces || !state.spaces.length) {
        return null
      }
      let space = state.spaces.find(s => s && s.configuration && s.configuration.centro_multilocacion_id)
      return space && space.configuration && space.configuration.centro_multilocacion_id
    },

    multilocacion_max(state) {
      return state.multilocacion_max
    },
  },

  mutations: {
    SET_SPACES(state, value) {
      state.spaces = value;
    },

    SET_LOADED(state, value) {
      state.loaded = value;
    },

    SET_STEP(state, value) {
      state.step = value;
    },

    SET_DAY(state, value) {
      state.day = value;
    },

    SET_ENABLED(state, value) {
      let localSpace = state.spaces[value];
      localSpace.enabled = !localSpace.enabled;
      state.spaces[value] = localSpace;
    },

    SET_VALUE(state, value) {
      let values = value.split(';').map(element => parseInt(element));
      let localSpaceConf = state.spaces[values[0]].configuration;
      localSpaceConf.value = values[1];
      state.spaces[values[0]].configuration = localSpaceConf;
    },

    SET_MULTILOCATION_CENTER(state, value) {
      let values = value.split(';').map(element => parseInt(element));
      let localSpaceConf = state.spaces[values[0]].configuration;
      localSpaceConf.centro_multilocacion_id = values[1];
      state.spaces[values[0]].configuration = localSpaceConf;
    },

    SET_DOCUMENT_URL(state, value) {
      state.documentUrl = value;
    },

    SET_CLIENT_SPACES_ONLY(state, value) {
      state.clientSpacesOnly = value;
    },

    SET_CAN_CHANGE_TO_CLIENT(state, value) {
      state.canChangeToClient = value;
    },

    SET_MAXIMO_MULTILOCACION(state, value) {
      state.multilocacion_max = value;
    },
  },

  actions: {
    // async loadSpaces({ commit }) {
    //   axios.get('/clasificaciones/all', ).then(response => {
    //     commit('reserve/SET_SPACES', response.data, {root:true})
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
    //   .finally(() => commit('SET_LOADED', true));
    // },

    setStep({ commit }, value) {
      commit('SET_STEP', value);
    },

    enable({ commit }, value) {
      commit('SET_ENABLED', value);
    },


    setDay({ commit }, value) {
      commit('SET_DAY', value);
    },

    setValue({ commit }, value) {
      commit('SET_VALUE', value);
    },

    setMultilocationCenter({ commit }, value) {
      commit('SET_MULTILOCATION_CENTER', value);
    },

    setDocumentUrl({ commit }, value) {
      commit('SET_DOCUMENT_URL', value);
    },

    setClientSpacesOnly({ commit }, value) {
      commit('SET_CLIENT_SPACES_ONLY', value);
    },

    makeReservation({ commit, rootGetters }) {
      commit('SET_LOADED', false)
      let day = rootGetters['reserve/systemDay']
      let center_id = rootGetters['centers/selectedCenter'].id
      let centro_multilocacion_id = rootGetters['reserve/centroMultilocacionId']
      let schedules_selected = rootGetters['schedule/schedulesSelected']
      let parkings = rootGetters['parkings/parkingSelected']
      let spaces = rootGetters['reserve/spaces']
      let schedules = {...schedules_selected, ...parkings }

      for (let index in schedules) {
        let quantity
        if (index == 4 || !spaces.find(o => o.id == index)) {
          quantity = 1
        } else {
          quantity = spaces.find(o => o.id == index).configuration.value
        }
        if (Array.isArray(schedules[index])) {
          schedules[index].forEach((o, key) =>schedules[index][key].quantity = quantity)
        } else {
          schedules[index].quantity = quantity
        }
      }

      return axios.post('/make-reservation', {
        fecha: day,
        centro_id: center_id,
        centro_multilocacion_id,
        schedules,
      }).then(response => {
        if (response.data.url) {
          commit('SET_DOCUMENT_URL', response.data.url)
        }

        commit('SET_LOADED', true)
      }).catch(error => {
        console.log(error)
      });
    },


    deleteReservation({ commit }, reserve_id) {
      commit('SET_LOADED', false)

      axios.post('/delete-reservation/'+reserve_id).then(response => {
        commit('SET_LOADED', true)
      }).catch(error => {
        console.log(error)
      });
    },


    async clearAllData({ commit, dispatch }) {
      commit('SET_LOADED', false)

      commit('schedule/SET_SPACES', {}, {root: true})
      commit('parkings/SET_PARKINGS', {}, {root: true})
      commit('schedule/SET_COFFEE_BREAK_ALL', {}, {root: true})
      commit('reserve/SET_DOCUMENT_URL', null, {root: true})
      commit('schedule/SET_SELECTED', {}, {root: true})
      commit('parkings/SET_SELECTED', [], {root: true})
      commit('centers/SET_SELECTED', 0, {root: true})

      commit('SET_STEP', 0);
      commit('SET_DAY', new Date());
      commit('SET_CLIENT_SPACES_ONLY', true);
      commit('SET_CAN_CHANGE_TO_CLIENT', true);

      await dispatch('clearEnables')
      await dispatch('clearMultilocacionCenterId')

      commit('SET_LOADED', true)
    },

    async clearReserveData({ commit, dispatch }) {
      commit('SET_LOADED', false)
      commit('schedule/SET_SPACES', {}, {root: true})
      commit('parkings/SET_PARKINGS', {}, {root: true})
      commit('schedule/SET_COFFEE_BREAK_ALL', {}, {root: true})
      commit('reserve/SET_DOCUMENT_URL', null, {root: true})
      commit('schedule/SET_SELECTED', {}, {root: true})
      commit('parkings/SET_SELECTED', [], {root: true})

      commit('SET_LOADED', true)
    },

    async clearEnables({commit, rootGetters}) {
      let spaces = rootGetters['reserve/spaces']
      spaces.forEach((space, index) => {
        if (space.enabled) {
          commit('SET_ENABLED', index)
        }
      });
    },

    async clearMultilocacionCenterId({commit, rootGetters}) {
      let spaces = rootGetters['reserve/spaces']
      spaces.forEach((space, index) => {
        if (space.centro_multilocacion_id) {
          commit('SET_MULTILOCATION_CENTER', index + ';')
        }
      });
    },

    async loadSpaces({ commit, rootGetters, dispatch }) {
      commit('SET_LOADED', false)

      let spaces
      let clientSpacesOnly = rootGetters['reserve/clientSpacesOnly']
      if (clientSpacesOnly) {
        spaces = await dispatch('loadClient')
        await dispatch('centers/loadClientCenters', null, {root: true})
        await dispatch('centers/loadMultilocacionCenters', null, {root: true})

        if (!spaces || spaces.length == 0) {
          commit('SET_CLIENT_SPACES_ONLY', false)
          commit('SET_CAN_CHANGE_TO_CLIENT', false)
          spaces = await dispatch('loadPronto')
          await dispatch('centers/loadCenters', null, {root: true})
        }

        let multilocacion = spaces.find(s => s.multilocacion_max)

        if (multilocacion) {
          commit('SET_MAXIMO_MULTILOCACION', multilocacion.multilocacion_max)
        }

      } else {
        spaces = await dispatch('loadPronto')
        await dispatch('centers/loadCenters', null, {root: true})
      }

      commit('SET_SPACES', spaces)
      commit('SET_LOADED', true)

      return spaces
    },

    loadPronto({ commit, rootGetters, dispatch }) {
      return axios.get('/classifications/all')
        .then(response => {
          return response.data
        })
        .catch(error => {
          console.error(error)
        })

    },

    loadClient({ commit, rootGetters, dispatch }) {
      return axios.get('/classifications/contract')
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(error)
      })
    },


    changeCenter({ commit }, index) {
      commit('centers/SET_SELECTED', index, {root: true});
      commit('schedule/SET_SPACES', {}, {root: true})
      commit('parkings/SET_PARKINGS', {}, {root: true})
    },

    removeBlock({ commit, rootGetters }, {index, index2, schedules, isParking}) {
      let spaceSelected = isParking ? rootGetters['parkings/parkings'][index][index2] : rootGetters['schedule/spaces'][index][index2]
      schedules.forEach((selected) => {
        let indexItem = spaceSelected.schedule.map(s => s.time).indexOf(selected)
        if (indexItem < 0) return
        if (isParking) {
          commit('parkings/SET_RESERVED', {
            index: index,
            selected: index2,
            value: indexItem
            // value, index, selected
          }, {root: true})
        } else {
          commit('schedule/SET_RESERVED', {
            space: index,
            spaceIndex: index2,
            scheduleIndex: indexItem
          }, {root: true})
        }
      })
      let result = isParking ? rootGetters['parkings/parkings'][index][index2] : rootGetters['schedule/spaces'][index][index2]
      let count = result.schedule.filter(o => o.reserved).length
      if (!count) {
        let selected = isParking ? rootGetters['parkings/selected'] : rootGetters['schedule/selected']
        let spaces = isParking ? rootGetters['parkings/parkings'] : rootGetters['schedule/spaces']
        if (isParking) {
          selected = selected.filter((v,k) => k != index)
          spaces = spaces.filter((v,k) => k != index)

        } else {

          if (Array.isArray(selected)) {
            selected[index] = selected[index].filter(s => s != index2)

            if (selected[index].length == 0) {
              let spaces2 = Object.assign({}, spaces)
              delete spaces2[index]
              spaces = spaces2
            }
          } else {
            let selected2 = Object.assign({}, selected)
            delete selected2[index]
            selected = selected2

            let spaces2 = Object.assign({}, spaces)
            delete spaces2[index]
            spaces = spaces2
          }

        }
        if (isParking) {
          commit('parkings/SET_SELECTED', selected, {root: true})
          commit('parkings/SET_PARKINGS', spaces, {root: true})
        } else {
          commit('schedule/SET_SELECTED', selected, {root: true})
          commit('schedule/SET_SPACES', spaces, {root: true})
        }
      }
    }
  },
}
