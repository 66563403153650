<template>
  <div class="w-full">
    <div class="h-12"></div>
    <p class="flex items-center justify-center h-12 text-sm text-gray-700">Pronto Espacios ©</p>
    <div class="h-3"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
