<template>
  <transition name="fade" v-bind="$attrs" v-on="$listeners">
    <div v-show="!loaded" class="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full h-screen overflow-hidden bg-gray-700 opacity-75">
      <div class="w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"></div>
        <!-- <h2 class="text-xl font-semibold text-center text-white">Loading...</h2>
      <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p> -->
    </div>
  </transition>

</template>

<script>

import { mapGetters } from 'vuex'

export default {
  inheritAttrs: false,

  computed: {
    ...mapGetters({
      authLoaded: 'auth/loaded',
      centerLoaded: 'centers/loaded',
      reserveLoaded: 'reserve/loaded',
      myReservesLoaded: 'myReserves/loaded',
      schedulesLoaded: 'schedule/loaded',
    }),

    loaded() {
      return this.authLoaded &&
        this.centerLoaded &&
        this.reserveLoaded &&
        this.myReservesLoaded &&
        this.schedulesLoaded
    }
  },

}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
  }
</style>
