<template>
  <div class="w-full pt-16 xl:pt-24">
    <div class="flex flex-col items-center justify-center">
      <div class="flex items-center justify-center w-full h-16 px-6 mb-8 md:px-16 md:justify-start xl:justify-center">
        <p class="text-xl font-semibold text-gray-900 md:text-2xl xl:text-32">Nuestros centros</p>
      </div>
      <div class="w-full px-6 select-none md:flex md:justify-between md:px-16 md:flex-wrap xl:w-xl xl:px-0 xl:flex-none xl:flex-nowrap xl:overflow-x-scroll">
        <div class="relative w-full mb-12 cursor-pointer h-50 md:w-76 md:h-45 xl:w-80 xl:flex-shrink-0 xl:mr-10" v-for="(center, index) in centers" :key="'center-'+index" @click.prevent="goToReserve(index)">
          <ImageComponent :height="50" :image="center.prefix"/>
          <div class="absolute bottom-0 right-0 mb-6 md:mb-0">
            <div class="flex items-center justify-start w-40 h-10 bg-gradient-to-r from-pronto-800 to-pronto-600">
              <p class="ml-5 text-sm font-medium text-white">{{center.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ImageComponent from '@/components/ImageComponent.vue'

export default {
  components: {
    ImageComponent
  },

  created() {
    // this.setCenterIndex(0);
  },

  computed: {
    ...mapGetters({
      centers: 'centers/centers'
    }),
  },

  methods: {
    ...mapActions({
      setCenterIndex: 'centers/setSelected'
    }),

    goToReserve(index) {
      this.setCenterIndex(index);
      this.$router.push({ name: 'Reserve' });
    }
  }
}
</script>
