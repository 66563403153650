import axios from '@/axios'
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    token: null,
    user: {},
    client: {},
    loaded: true,
    profile: '',
    clientList: null,
  },

  getters: {
    authenticated(state) {
      return !!state.token
    },

    user(state) {
      return state.user
    },

    loaded(state) {
      return state.loaded
    },

    initials(state) {
      return !Object.keys(state.user).length ? '' : state.user.nombre.charAt(0) + state.user.apellido.charAt(0)
    },

    clientName(state) {
      return !Object.keys(state.client).length ? '' : state.client.razonSocial
    },

    user_admin(state) {
      return state.profile == 'Administrador'
    },

    client(state) {
      return state.client
    },

    user_only_contracts(state) {
      return state.profile == 'Usuario - sólo servicios contratados'
    },

    clientList(state) {
      return state.clientList
    },
  },

  mutations: {
    SET_USER(state, value) {
      state.user = value
    },

    SET_LOADED(state, value) {
      state.loaded = value
    },

    SET_TOKEN(state, value) {
      state.token = value
    },

    SET_CLIENT(state, value) {
      state.client = value
    },

    SET_PROFILE(state, value) {
      state.profile = value
    },

    SET_CLIENT_LIST(state, value) {
      Vue.set(state, 'clientList', [...value]);
      // state.clientList = value
    },

    SET_CLIENT_LIST_2(state, value) {
      state.clientList = value
    },
  },

  actions: {
    async userIsLoggedIn({ commit, dispatch }) {
      let token = localStorage.getItem('token')

      if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        let response = await axios.get('user')
        let user = response.data

        commit('SET_TOKEN', token)
        commit('SET_USER', user)
        commit('SET_CLIENT', user.cliente)
        commit('SET_PROFILE', user.role.nombre)
        commit('SET_LOADED', true)
        return true
      }
      return false
    },

    async login({ commit, dispatch }, credentials) {
      let userAgent = navigator.userAgent
      credentials.device = userAgent.substring(0, userAgent.indexOf(' '))
      commit('SET_LOADED', false)

      return new Promise((resolve, reject) => {
        axios.post('login', credentials)
          .then(resp => {
            if (resp.data.message == 'Success') {
              let token = resp.data.token
              let user = resp.data.user

              localStorage.setItem('token', token)
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

              commit('SET_USER', user)
              commit('SET_CLIENT', user.cliente)
              commit('SET_PROFILE', user.role.nombre)
              commit('SET_CLIENT_LIST_2', null)

              console.log({token})

              resolve()
            } else {
              let clientList = resp.data.clients
              console.log({clientList})
              commit('SET_CLIENT_LIST', clientList)
              resolve()
            }
          })
          .catch(err => {
            // dispatch('auth/logout', null, {root: true})
            return reject(err)
          })
          .finally(() => commit('SET_LOADED', true))
      })
    },

    async recoveryEmail({ commit }, data) {
      let response = await axios.post('recovery-password/step-1', data)
      return response
    },

    async confirmPassword({ commit }, data) {
      let response = await axios.post('recovery-password/step-2', data)
      return response
    },

    async register({ dispatch }, credentials) { },

    async logout({ commit }) {
      await axios.post('logout')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
    },

    async clearClientList({ commit }) {
      commit('SET_CLIENT_LIST_2', null)
    }
  }
}
