<template>
  <img :class="imageClass" :src="getImagePath(image)">
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: [
    'height',
    'image',
  ],

  computed: {
    ...mapGetters({
      centers: 'centers/centers'
    }),

    imageClass() {
      return 'w-full h-' + this.height + ' object-cover'
    }
  },

  methods: {
    getImagePath(image) {
      return image
      // let images = require.context('../assets/images', false, /\.jpeg$/);
      // return images('./' + centerPrefix + ".jpeg");
    },
  }
}
</script>
