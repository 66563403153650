import axios from 'axios'
import https from 'https'
// import store from 'store'
import router from '@/router'
import auth from '@/store/auth.js'

let ax = axios.create({
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
    baseURL: process.env.VUE_APP_API_URL,
})

console.log(process.env.VUE_APP_API_URL)

ax.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    const loginpath = window.location.pathname;
    router.replace({name: 'Login', query: { from: loginpath }});
  }
  return Promise.reject(error)
})
export default ax
