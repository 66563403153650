<template>
  <div class="w-full">
    <!-- Select Space Modal -->
    <div class="hidden md:block">
      <div class="fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-50" v-if="modal.show"></div>
      <div class="fixed inset-0 z-40 grid w-full h-full" v-if="modal.show" @click.prevent="hideModal(1)">
        <div class="py-6 m-auto overflow-y-scroll bg-white border border-gray-200 rounded shadow-lg w-calendarTablet max-h-96" @click.prevent="hideModal(2)">
          <div class="relative flex items-center w-full px-6">
            <div class="absolute w-5 h-5 ml-3">
              <IconComponent prefix="search_gray"/>
            </div>
            <input class="flex items-center w-full h-10 pl-10 pr-4 font-light text-gray-800 border border-gray-200 rounded bg-gray-50 focus:outline-none" v-model="query" type="text" placeholder="Ingrese un nombre de estacionamiento">
          </div>
          <div class="mt-4">
            <div class="w-full" v-for="(queryItem, queryIndex) in queryItems" :key="'queryItem-'+modal.index+'-'+queryIndex" @click="selectParking(modal.index, queryIndex)">
              <div class="flex items-center justify-between w-full h-10 px-6">
                <p class="text-sm text-gray-700 select-none">{{queryItem.name}}</p>
                <div class="w-3 h-3">
                  <span class="flex w-3 h-3" v-if="queryIndex == selectedParking[modal.index]">
                    <span class="absolute inline-flex w-3 h-3 rounded-full opacity-75 bg-prontogreen-200 animate-ping"></span>
                    <span class="relative inline-flex w-3 h-3 rounded-full bg-prontogreen-500"></span>
                  </span>
                </div>
              </div>
              <div class="w-full h-px bg-gray-200" v-if="queryIndex < queryItems.length - 1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full" v-if="loaded">
      <div  class="mt-10 md:flex md:justify-start md:flex-col md:items-center">

        <!-- sm -->
        <div class="w-full mb-8 border border-gray-200 rounded md:hidden" v-for="(selected, index) in selectedParking" :key="'parkings-'+index">
          <div class="w-full h-40 rounded-t" v-if="!isDropdownOpen[index]">
            <img class="object-cover w-full h-full" src="@/assets/images/home.jpeg">
          </div>
          <div class="flex items-center justify-between w-full px-4 py-4 text-white bg-pronto-300" @click.prevent="toogleDropdown(index)">
            <div class="flex items-center">
              <IconComponent prefix="car" white/>
              <p class="ml-5 ">{{parkings[index][selected].name}}</p>
            </div>
            <div class="w-5 h-5 focus:outline-none">
              <IconComponent v-if="!isDropdownOpen[index]" prefix="chevron-down" white/>
              <IconComponent v-else prefix="x_gray" white/>
            </div>
          </div>
          <div class="p-4" v-if="!isDropdownOpen[index]">
            <div class="flex flex-wrap justify-between w-full">
              <div class="flex items-center justify-center h-8 mb-2 font-light text-gray-700 border border-gray-200 rounded-sm cursor-pointer w-14 md:w-18" v-bind:class="{'bg-pronto-300 border-none': parking.reserved, 'bg-pronto-100': parking.available && getSafe2(highlights, [index, 'schedules', parkingIndex])}"
              v-for="(parking, parkingIndex) in parkings[index][selected].schedule" :key="'scheduleParking-'+index+'-'+parkingIndex"
              @mouseover="handleMouseoverSchedule(index,parkingIndex)" @mouseleave="handleMouseleaveSchedule(index,parkingIndex)" @click.prevent="selectSchedule(index, selected, parkingIndex)">
                <p class="flex items-center justify-center h-8 select-none" v-bind:class="{'text-gray-200 cursor-not-allowed': !parking.available, 'text-white': parking.reserved}">{{parking.time}}</p>
              </div>
            </div>
          </div>
          <div class="mt-4" v-else>
            <div class="relative flex items-center w-full px-4">
              <div class="absolute w-5 h-5 ml-3">
              <IconComponent prefix="search_gray"/>
              </div>
              <input class="flex items-center w-full h-10 pl-10 pr-4 font-light text-gray-800 border border-gray-200 rounded bg-gray-50 focus:outline-none" v-model="query" type="text">
            </div>
            <div class="mt-4">
              <div class="w-full" v-for="(queryItem, queryIndex) in queryItems" :key="'queryItem-'+modal.index+'-'+queryIndex" @click="selectParking(index, queryIndex)">
                <div class="flex items-center justify-between w-full h-10 px-4">
                  <p class="text-sm text-gray-700">{{queryItem.name}}</p>
                  <div class="w-3 h-3">
                    <span class="flex w-3 h-3" v-if="queryIndex == selectedParking[modal.index]">
                      <span class="absolute inline-flex w-3 h-3 rounded-full opacity-75 bg-pronto-100 animate-ping"></span>
                      <span class="relative inline-flex w-3 h-3 rounded-full bg-pronto-300"></span>
                    </span>
                  </div>
                </div>
                <div class="w-full h-px bg-gray-200" v-if="queryIndex < queryItems.length - 1"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- md + -->

        <div class="hidden mb-8 border border-gray-200 rounded md:block" v-for="(selected, index) in selectedParking" :key="'parkingsMD-'+index">

          <div class="flex flex-col">
            <div class="relative flex items-center justify-center px-4 py-3 overflow-hidden text-white border-b border-gray-200 cursor-pointer bg-pronto-300"  @click.prevent="toogleDropdown(index)">
              <div class="mr-4">
              <IconComponent prefix="car" white/>
              </div>
              <p class="font-medium ">{{parkings[index][selected].name}}</p>

              <div class="absolute w-5 h-5 top-4 right-4">
                <IconComponent prefix="chevron-down" white/>
              </div>
            </div>
            <div class="flex w-full bg-white">
              <div class="border-r border-gray-200 w-50 h-70">
                <img class="object-cover w-full h-full" src="@/assets/images/estacionamiento-3.jpeg">
              </div>
              <div class="h-full">

                <div class="flex flex-wrap justify-between px-4 py-5 w-108">
                  <div class="flex items-center justify-center h-8 mb-2 font-light text-gray-700 border border-gray-200 rounded-sm cursor-pointer w-14 md:w-18" v-bind:class="{'bg-pronto-300 border-none': parking.reserved, 'bg-pronto-100': parking.available && getSafe2(highlights, [index, 'schedules', parkingIndex])}"
                  v-for="(parking, parkingIndex) in parkings[index][selected].schedule" :key="'scheduleParkingMD-'+index+'-'+parkingIndex"  @mouseover="handleMouseoverSchedule(index,parkingIndex)" @mouseleave="handleMouseleaveSchedule(index,parkingIndex)" @click.prevent="selectSchedule(index, selected, parkingIndex)">
                    <p class="flex items-center justify-center h-8 select-none" v-bind:class="{'text-gray-200 cursor-not-allowed': !parking.available, 'text-white': parking.reserved}">{{parking.time}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full" v-if="getBlocksSchedules(index).length">
              <div class="flex items-center justify-around w-full px-4 border-t border-gray-200 bg-gray-50">
                <div class="flex items-center text-center">
                  <span class="text-gray-700 text-xs">
                    Bloques seleccionados
                  </span>
                </div>
                <div class="grid w-full gap-1 px-6 mt-4 grid-cols-4">
                  <div v-for="(schedule, index2) in getBlocksSchedules(index)" :key="'blocks-schedules-'+index"
                  class="text-center py-1 mb-2 border rounded focus:outline-none bg-pronto-300 border-white text-white font-semibold">
                    <span class="text-sm">{{schedule.start_time}}-{{schedule.end_time}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import IconComponent from '@/components/IconComponent.vue'
import {groupSchedules, getSafe2} from '@/helpers'

export default {
  data() {
    return {
      title: 'Seleccione el estacionamiento',
      isDropdownOpen: [],
      query: '',
      modal: {
        index: 0,
        show: false,
        hideModal: true,
      },
      highlights: {}
    }
  },

  components: {
    IconComponent,
  },

  async mounted() {
    this.loadParkings().then(() => {
      this.isDropdownOpen = this.selectedParking.map(_ => false)
    }).then(() => {
      this.highlights = Object.keys(this.parkings).reduce((total, index) => {
        let parkings = this.parkings[index][this.selectedParking[index]].schedule
        let schedules = parkings.map((_) => 0)
        total[index] = {
          schedules: schedules,
          lastSchedule: null,
        }
        return total
      }, {})
    })
  },

  computed: {
    ...mapGetters({
      parkings: 'parkings/parkings',
      selectedParking: 'parkings/selected',
      loaded: 'parkings/loaded',
      step: 'reserve/step',
      day: 'reserve/day',
    }),

    queryItems() {
      let index = this.modal.index
      let result = {}
      this.parkings[index].forEach((value, key) => {
        let acceptedQuery = !this.selectedParking.includes(key)
          && new RegExp(".*" + this.query + ".*", "i").test(value.name)

        if (acceptedQuery) {
          result[key] = value
        }
      })
      return result;
    },
  },

  methods: {
    ...mapActions({
      loadParkings: 'parkings/loadParkings',
      setReserved: 'parkings/setReserved',
      setSelected: 'parkings/setSelected',
      clearReserved: 'parkings/clearReserved',
    }),


    selectSchedule(index, selected, parkingIndex) {
      this.selectHighlightSchedule(index, selected, parkingIndex)
    },


    toogleDropdown(index) {
      this.$set(this.modal, 'index', index)
      this.$set(this.modal, 'show', !this.modal.show)
      this.$set(this.isDropdownOpen, index, !this.isDropdownOpen[index])
      if (!this.isDropdownOpen[index]) {
        this.query = ''
        this.clearReserved(index)
      }
    },

    selectParking(index, itemIndex) {
      this.$set(this.selectedParking, index, parseInt(itemIndex))
      this.setSelected(this.selectedParking)
      this.toogleDropdown(index)
    },

    hideModal(divIndex) {
      if (divIndex == 1) {
        if (this.modal.hideModal) {
          this.modal.index = 0;
          this.toogleDropdown(this.modal.index)
        }
        else {
          this.modal.hideModal = true;
        }
      }
      else {
        this.modal.hideModal = false;
      }
    },


    handleMouseleaveSchedule(spaceIndex,index) {
      this.highlights[spaceIndex].schedules = this.highlights[spaceIndex].schedules.map((v,k) => 0)
    },

    handleMouseoverSchedule(parkingIndex, index) {
      let highlights = this.highlights[parkingIndex]
      let parkingSchedule = this.parkings[parkingIndex][this.selectedParking[parkingIndex]].schedule

      // clear
      let schedules = highlights.schedules.map((v,k) => 0)
      schedules[index] = 1

      if (highlights.lastSchedule !== null) {
        let start, end
        if (index < highlights.lastSchedule) {
          start = index
          end = highlights.lastSchedule
        } else {
          start = highlights.lastSchedule
          end = index
        }

        for (let i = start; i < end; i++) {
          if (!parkingSchedule[i].available) {
            highlights.schedules.map((v,k) => 0)
            schedules[index] = 1
            break
          }
          schedules[i] = 1
        }
      }
      highlights.schedules = schedules
    },

    selectHighlightSchedule(index, selected, parkingIndex) {
      let highlights = this.highlights[index]
      let schedules = this.parkings[index][this.selectedParking[index]].schedule
      if (!schedules[parkingIndex].available) {
        return
      }
      if (schedules[parkingIndex].reserved) {
        // delete group
        let start = parkingIndex, end = parkingIndex
        while (start >= 0 && schedules[start].reserved) {
          start--
        }
        while (end < schedules.length && schedules[end].reserved) {
          end++
        }
        if (start+1 == end-1) {
          if (highlights.lastSchedule === null) {
            this.setReserved({index, selected, value: parkingIndex});
          }
        } else {
          for (let i = start + 1; i < end; i++) {
            this.setReserved({index, selected, value: i});
          }
        }

        highlights.lastSchedule = null
        highlights.schedules = highlights.schedules.map((v,k) => 0)
        return
      }

      if (highlights.lastSchedule === null) {
        highlights.lastSchedule = parkingIndex
        this.setReserved({index, selected, value: parkingIndex})
        return
      } else {
        highlights.lastSchedule = null
      }

      highlights.schedules.forEach((value, i) => {
        if (value == 1 && !schedules[i].reserved) {
          this.setReserved({index, selected, value: i});
        }
      })
      highlights.schedules = highlights.schedules.map((v,k) => 0)
    },

    getSafe2(data, paths) {
      let res = getSafe2(data, paths)
      return res
    },

    getBlocksSchedules(index) {
      let selectedSchedules = this.parkings[index][this.selectedParking[index]].schedule
      selectedSchedules = selectedSchedules.filter(s => s.reserved)
      return groupSchedules(selectedSchedules)
    },
  },

}
</script>
