<template>
  <button type="button" class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none" v-bind:class="[enabled ? bgColor : '']" aria-pressed="false" @click.prevent="click()">
    <span class="sr-only">Use setting</span>
    <span class="relative inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0" v-bind:class="{'translate-x-5' : enabled}">
      <span class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity duration-200 ease-in opacity-100" v-bind:class="{'opacity-0 ease-out duration-100' : enabled}" aria-hidden="true">
        <svg class="w-3 h-3 text-gray-400 bg-white fill-current" fill="none" viewBox="0 0 12 12">
          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity duration-100 ease-out opacity-0" v-bind:class="{'opacity-100 ease-in duration-200': enabled}" aria-hidden="true">
        <svg class="w-3 h-3 bg-white" :class="textColor" fill="currentColor" viewBox="0 0 12 12">
          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
        </svg>
      </span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'prontogreen-500',
    },
    enabled: {
      // type: Boolean,
      required: true,
    }
  },
  computed: {
    bgColor() {
      return 'bg-'+ this.color
    },

    textColor() {
      return 'text-'+ this.color
    }
  },
  // data() {
  //   return {
  //     enabled: false
  //   }
  // },
  methods: {
    click() {
      // this.enabled = !this.enabled;
      this.$emit('update:enabled', !this.enabled);
    }
  }
}
</script>
