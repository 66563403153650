<template>
  <div class="w-full h-full overflow-y-scroll xl:h-screen xl:overflow-y-hidden xl:flex xl:justify-center">
    <div class="px-6 md:px-16 xl:w-160">
      <div class="py-4 md:py-6">
        <button class="h-8 md:h-12 focus:outline-none" @click.prevent="goToLogin()">
          <!-- <img class="h-8 md:h-12 xl:h-full" src="@/assets/logos/full.svg"> -->
          <div class="">
            <img class="h-8 md:h-12" src="@/assets/logos/Pronto_Logo H_negro 1.png">
          </div>
        </button>
      </div>
      <p class="mt-4 text-2xl font-bold text-gray-800 md:mt-12 md:text-3xl">Recuperar contraseña</p>
      <p class="mt-12 text-gray-700 text-md md:mt-12 md:text-md">
        ¡Excelente!
        A continuación, confirme el correo electrónico enviado a su cuenta de email y siga los pasos que se le indican en él.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPasswordWaitEmail',
  data () {
    return {
      form: {
        email: '',
      },
      errors: {
        email: '',
      },
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      recoveryEmail: "auth/recoveryEmail",
    }),

    async submit () {
      this.recoveryEmail(this.form)
      .then(res => {
        this.$router.replace({name: 'RecoveryPassword2'});
      })
      .catch(err => {
        console.log(err.response.data.errors)

        let errors = err.response.data.errors
        this.errors.email = errors.email ? errors.email[0] : ''
      })
    },

    redirectTo(path) {
      this.$router.push({path}).catch(()=>{})
    },

    goToLogin() {
      this.$router.push({ path: '/ingresar' }).catch(()=>{})
    }
  }
}
</script>
