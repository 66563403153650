<template>
  <svg v-tooltip="{
    content: content,
    delay: {
      show: 100,
      hide: 300,
      classes: ['w-30'],
    },
    trigger: 'hover focus click'
  }" :class="icon_class" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
  </svg>
</template>

<script>
export default {
  props: {
    content: {required:true},
    icon_class: {default: 'ml-2 w-5 h-5 font-bold'},
  },
}
</script>
