import Vue from 'vue'
import Vuex from 'vuex'
import centers from './centers.js'
import reserve from './reserve.js'
import schedule from './schedule'
import parkings from './parkings'
import myReserves from './myReserves'
import auth from './auth'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    centers,
    reserve,
    schedule,
    parkings,
    myReserves,
  },
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: (key) => Cookies.get(key),
  //       // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
  //       setItem: (key, value) =>
  //         Cookies.set(key, value, { expires: 3, secure: true }),
  //       removeItem: (key) => Cookies.remove(key),
  //     },
  //   }),
  // ],
})
