import axios from '@/axios';
import { humanizeDateShort, parseDate } from '@/helpers';

export default {
  namespaced: true,

  state: {
    homeReserves: [],
    reserves: [],
    loaded: true,
    keys: [],
    current_page: 1,
    last_page: 10,
    per_page: 10,
    total: 1,
  },

  getters: {
    reserves(state) {
      return state.reserves;
    },

    loaded(state) {
      return state.loaded;
    },

    keys(state) {
      return state.keys;
    },

    homeReserves(state) {
      return state.homeReserves;
    }
  },

  mutations: {
    SET_RESERVES(state, value) {
      state.reserves = value;
    },

    SET_HOME_RESERVES(state, value) {
      state.homeReserves = value;
    },

    SET_KEYS(state, value) {
      state.keys = value;
    },

    SET_LOADED(state, value) {
      state.loaded = value;
    },

    SET_PAGES(state, value) {
      state.current_page = value.current_page
      state.last_page = value.last_page
      state.per_page = value.per_page
      state.total = value.total
    }
  },

  actions: {
    async loadMyReserves({ commit, rootGetters }, data) {
      commit('SET_LOADED', false);
      return axios.get('/my-reservation', {
        params: data
      }).then(response => {
        let reserves
        let user = rootGetters['auth/user'];
        let is_user_admin = rootGetters['auth/user_admin'];

        if (!response.data.data.data) {
          reserves = [];
        } else {
          reserves = Object.keys(response.data.data.data).map((res_id) => {
            let res = response.data.data.data[res_id]
            let res0 = res[0]

            let statusReserve = (status) => {
              if (status == 'Preparada') {
                status = 'Reservada'
              }
              return status
            }
            let statusClassReserve = (status) => {
              if (status == 'Finalizada') {
                return 'bg-blue-100 text-blue-800'
              }
              if (status == 'Reservada') {
                return 'bg-prontogreen-100 text-prontogreen-800'
              }
              if (status == 'Anulada') {
                return 'bg-red-100 text-red-800'
              }
              return 'bg-gray-100 text-gray-800'
            }
            return {
              id: res_id,
              centro: [{ text: res0.producto ? (res0.producto.centro ? res0.producto.centro.nombre : '') : '' }],
              reserva: [{
                html: res.reduce((text, value, i) => {
                  return text
                    + '<div class="flex justify-between gap-4">'
                    + '<div class="">'
                    + (value.producto ? value.producto.nombre : '')
                    + '</div></div>'
                }, '')
              }],
              horario: [{
                html: res.reduce((text, value, i) => {
                  return text
                    + '<div class="flex justify-between gap-4">'
                    + '<div class="text center">'
                    + (`${value.start_time} - ${value.end_time}`)
                    + '</div></div>'
                }, '')
              }],
              coffee: [{
                html: res.reduce((text, value, i) => {
                  return text
                    + '<div class="flex justify-between gap-4">'
                    + '<div class="text center">'
                    + (`${value.coffee_break ? 'Sí' : 'No'}`)
                    + '</div></div>'
                }, '')
              }],

              contratado: [{ text: res0.contratado ? 'Si' : 'No' }],
              fecha: [{ text: res0.start_date }],
              estado: [{
                label: statusReserve(res0.estado),
                class: statusClassReserve(res0.estado),
              }],
              acciones: [
                {
                  link: res0.urlArchivoReserva,
                  icon: res0.urlArchivoReserva ? 'document_download' : null,
                },
                {
                  icon: 'trash',
                  class: 'text-red-800',
                  event: 'delete-reserve',
                  if: res0.estado == 'Reservada' && (is_user_admin || res0.persona_id == user.id)
                }
              ],
            }
          })
        }
        commit('SET_RESERVES', reserves)
        commit('SET_PAGES', response.data)
        let table_keys = [
          { name: "Centro", key: 'centro' },
          { name: "Reserva", key: 'reserva' },
          { name: "Horario", key: 'horario' },
          { name: "Coffee break", key: 'coffee' },
          { name: "Fecha", key: 'fecha' },
          { name: "Estado", key: 'estado' },
          { name: "Contratado", key: 'contratado' },
          { name: "Acciones", key: 'acciones' },
          // {name: "", key: 'eliminar'},
        ]
        commit('SET_KEYS', table_keys)
        return response.data
      })
        .catch(error => {
          console.log(error)
        })
        .finally(() => commit('SET_LOADED', true));
    },

    setReserves({ commit }, reserves) {
      commit('SET_RESERVES', reserves);
    },

    setKeys({ commit }, keys) {
      commit('SET_KES', keys);
    },

    setLoaded({ commit }, loaded) {
      commit('SET_LOADED', loaded);
    },

    async loadHomeReserves({ commit, rootGetters }) {
      commit('SET_LOADED', false);

      return axios.get('/reservation-home').then(response => {
        let reserves = response.data.map((res) => {
          return {
            id: res.id,
            centro: res.producto ? (res.producto.centro ? res.producto.centro.nombre : '') : '',
            reserva: res.producto ? res.producto.nombre : '',
            imagen: res.producto ? res.producto.image_url : '',
            fecha: humanizeDateShort(parseDate(res.start_date, 'YYYY-MM-DD')),
            horario: `${res.start_time} - ${res.end_time}`,
            coffee: res.coffee_break ? 'Sí' : 'No',
            link: res.urlArchivoReserva,
          }
        })
        commit('SET_HOME_RESERVES', reserves)
        return reserves
      })
        .catch(error => {
          console.log(error)
        })
        .finally(() => commit('SET_LOADED', true));
    },
  }
}
