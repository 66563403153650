<template>
  <div class="flex items-center justify-center w-full h-full antialiased " id="app">
    <LoadingComponent/>
    <div class="w-full overflow-x-hidden mobile:w-mobile md:w-md xl:w-xl">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@/axios'
import LoadingComponent from './components/LoadingComponent.vue'

export default {
  components: {
    LoadingComponent,
  },

  computed: {
    ...mapGetters({
      // loaded: 'auth/loaded',
      // loadCenters: 'reserve/loadCenters'
    })
  },

  async created() {
    // this.loadCenters()

    let loggedIn = await this.userIsLoggedIn()
    if (this.$route.requiresAuth && !loggedIn) {
      const loginpath = window.location.pathname;
      this.$router.replace({name: 'Login', query: { from: loginpath }});
    }
  },

  methods: {
    ...mapActions({
      userIsLoggedIn: 'auth/userIsLoggedIn',
    })
  }
}
</script>
