<template>
  <div class="w-full overflow-hidden bg-pronto-500 md:flex xl:justify-between">
    <div class="px-10 py-12 md:pr-0 md:pl-16 md:pb-0 md:pt-12 xl:pt-16">
      <div class="flex items-center justify-center xl:w-134">
        <p class="font-semibold text-28 text-white text-center md:text-left md:text-32 xl:text-5xl xl:leading-normal">{{title}}</p>
      </div>
      <div class="flex items-center justify-center mt-8 md:justify-start md:mt-12 xl:mt-16">
        <button class="w-36 h-10 flex items-center justify-center rounded-sm bg-pronto-300 focus:outline-none md:w-40 md:h-12 xl:w-54 xl:h-14" @click.prevent="callToAction()">
          <p class="h-10 flex items-center justify-center font-semibold text-18 text-xl text-white xl:text-2xl">Reservar</p>
        </button>
      </div>
    </div>
    <div class="hidden w-92 h-74 relative flex-shrink-0 md:block xl:w-134 xl:h-110">
      <svg fill="currentColor" viewBox="0 0 100 100" class="absolute inset-y-0 z-20 h-full text-pronto-500">
        <polygon id="diagonal" points="0,0 100,0 30,0 0,100"></polygon>
      </svg>
      <img class="w-full h-74 object-cover select-none xl:h-110" src="@/assets/images/home.jpeg">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Encuentra el espacio que deseas'
    }
  },

  methods: {
    callToAction() {
     this.$router.push({ name: 'Reserve' });
    }
  }
}
</script>
