import globals from '@/store/globals';
import moment from 'moment';

function strcmp ( str1, str2 ) {
  // http://kevin.vanzonneveld.net
  // +   original by: Waldo Malqui Silva
  // +      input by: Steve Hilder
  // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +    revised by: gorthaur
  // *     example 1: strcmp( 'waldo', 'owald' );
  // *     returns 1: 1
  // *     example 2: strcmp( 'owald', 'waldo' );
  // *     returns 2: -1

  return ( ( str1 == str2 ) ? 0 : ( ( str1 > str2 ) ? 1 : -1 ) );
};

function groupSchedules (bloques) {
  let ultimoValor = '00:00';
  let minutes = 30;
  let grupo = -1;
  let bloquesReservaAgr = [];
  for (let i = 0; i < bloques.length; i++) {
      let bloque = bloques[i]
      let inicio = bloque['time']
      let hm = inicio.split(':')
      let dt = new Date(0,0,0,hm[0],hm[1]);
      dt.setMinutes( dt.getMinutes() + minutes )
      let termino = dt.getHours().toString().padStart(2,'0') + ':' + dt.getMinutes().toString().padStart(2,'0')

      if (inicio != ultimoValor) {
          grupo += 1;
          bloquesReservaAgr[grupo] = {
              start_time: inicio,
          }
      }
      bloquesReservaAgr[grupo]['end_time'] = termino;

      ultimoValor = termino;

  }
  return bloquesReservaAgr;
};

function groupToItemsSchedule(grupo) {
  let minutos = 30;
  let res = []

  for (let i = 0; i < grupo.length; i++) {
    let item = grupo[i]
    let inicio = item.start_time
    let termino = item.end_time
    let actual = inicio

    do {
      res.push(actual)

      let hm = actual.split(':')
      let dt = new Date(0, 0, 0, hm[0],hm[1]);
      dt.setMinutes( dt.getMinutes() + minutos )
      actual = dt.getHours().toString().padStart(2,'0') + ':' + dt.getMinutes().toString().padStart(2,'0')
    } while (actual < termino)
  }

  res = res.sort((a, b) => strcmp(a, b));
  return res
};

function range(start, stop) {
  var a = [start], b = start;
  while (b < stop) {
      a.push(b += 1 || 1);
  }
  return a;
}

function stringToSlug (str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
};

function cloneObject (o) {
  return JSON.parse(JSON.stringify(o))
};

function isString (obj) {
  return (Object.prototype.toString.call(obj) === '[object String]');
};

function humanizeDate (date) {
  return date.getDate() + ' de ' + globals.monthNames[date.getMonth()] + ' de ' + date.getFullYear()
};

function humanizeDateShort (date) {
  return date.getDate() + ' de ' + globals.monthNames[date.getMonth()]
};

function parseDate (date, format) {
  return moment(date, format).toDate()
};

function formatDate (date, format) {
  return moment(date).format(format);
};

function compareDateNow (date) {
  return moment().diff(date, 'minutes')
};

function getSafe(fn, defaultVal) {
  try {
      return fn();
  } catch (e) {
      return defaultVal;
  }
};

function getSafe2(data, paths) {
  return paths.reduce((object, path) => {
    return (object || {})[path];
  }, data)
};



export {
  groupSchedules,
  stringToSlug,
  cloneObject,
  isString,
  humanizeDate,
  humanizeDateShort,
  parseDate,
  formatDate,
  compareDateNow,
  range,
  strcmp,
  groupToItemsSchedule,
  getSafe,
  getSafe2,
};
