<template>
  <div class="w-full md:bg-gray-100">
    <!-- Delete modal -->
    <div class="fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-50" v-if="modal.show"></div>
    <div class="fixed inset-0 z-40 grid w-full h-full" v-if="modal.show" @click.prevent="hideModal(1)">
      <div class="py-6 m-auto bg-white border border-gray-200 rounded shadow-lg w-calendarTablet" @click.prevent="hideModal(2)">
        <div class="px-6">
          <p class="text-lg font-semibold text-gray-700">Seleccione bloques a anular</p>
        </div>
        <div class="flex flex-wrap items-center w-full px-6 mt-4">
          <button v-for="(schedule, index) in modal.groupModalSchedule" :key="'coffee-selection'+index" @click="selectItemModal(index)"
          class="px-3 py-1 mb-2 mr-2 border border-gray-200 rounded focus:outline-none" v-bind:class="{'border-red-500': modal.selected[index]}">
            <span class="text-sm font-semibold " :class="{'text-red-500 ': modal.selected[index], 'text-gray-600': !modal.selected[index]}">{{schedule.start_time}}-{{schedule.end_time}}</span>
          </button>
        </div>

        <div class="flex flex-wrap items-center justify-around w-full px-6 mt-4">
          <button type="button" class="justify-center w-1/3 px-4 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click.prevent="hideModal(1)">
            Cancelar
          </button>

          <button v-if="canDelete" type="button" class="justify-center w-1/3 px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm hover:bg-red-700 focus:ring-red-500" @click="submitDelete">
            Anular
          </button>

          <button v-else type="button" class="justify-center w-1/3 px-4 py-2 text-base font-medium text-white bg-gray-400 border border-transparent rounded-md shadow-sm cursor-default focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
            Anular
          </button>
        </div>

      </div>
    </div>
    <!-- End delete modal -->

    <div class="w-full px-6 pt-12 md:pt-16 xl:pt-8">
      <p class="text-center text-gray-800 h-7 md:h-10 md:flex md:justify-center md:items-center">{{title}}</p>
    </div>
    <div class="mt-16 xl:mt-10 xl:mb-12">
      <p class="text-lg font-bold text-center text-gray-800 h-7 md:text-2xl">{{centers[centerSelected].name}}</p>
      <p class="mt-2 text-sm text-center text-gray-700 h-7 md:text-base">{{day}}</p>
    </div>
    <div class="xl:h-110 xl:overflow-y-scroll">
      <div class="w-full px-6 mt-11 md:flex md:flex-col md:items-center">
        <div class="w-full mb-8 bg-white border border-gray-200 rounded md:w-calendarTablet" v-for="(space, index) in spaces" :key="'space-'+index" >
          <div class="flex items-center justify-between w-full h-12 px-3 bg-white">
            <div class="flex items-center w-full h-12">
              <IconComponent :prefix="space[spacesSelected[index]].prefix"/>
              <p class="ml-4 text-gray-700">{{space[spacesSelected[index]].name}}</p>
            </div>
            <p v-if="getQuantity(index)" class="font-light text-gray-500"><span class="font-medium">{{ getQuantity(index) }}</span> personas</p>
            <img class="cursor-pointer" src="@/assets/icons/trash.svg" alt="" @click="openModal(index, false)">
          </div>
          <div class="w-full h-px bg-gray-200"></div>
          <div class="p-3">
            <div class="flex flex-wrap">
                <div class="mr-2" v-for="(schedule, scheduleIndex) in getOnlyReservedSpaces(space[spacesSelected[index]].schedule)" :key="'scheduleReserved'+scheduleIndex">
                  <div class="flex flex-col items-center justify-center mb-2">
                    <p class="flex flex-col items-center justify-center w-32 h-8 font-light text-gray-700 border border-gray-200 rounded-sm cursor-pointer">
                      <span>{{formatInterval(schedule)}}</span>
                    </p>
                    <span v-if="coffeeBreak[index] && coffeeBreak[index][scheduleIndex]" class="text-sm text-gray-500">Coffee break</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="w-full px-6 md:flex md:flex-col md:items-center" v-if="isEnabledParking">
        <div class="w-full mb-8 bg-white border border-gray-200 rounded md:w-calendarTablet" v-for="(parking, index) in parkings" :key="'parking-'+index">
          <div class="flex items-center justify-between w-full h-12 px-3 bg-white">
            <div class="flex items-center w-full h-12">
              <IconComponent :prefix="parking[parkingSelected[index]].prefix"/>
              <p class="ml-4 text-gray-700">{{parking[parkingSelected[index]].name}}</p>
            </div>
            <img class="cursor-pointer" src="@/assets/icons/trash.svg" alt="" @click="openModal(index, true)">
          </div>
          <div class="w-full h-px bg-gray-200"></div>
          <div class="p-3">
            <div class="flex flex-wrap">
                <div class="mr-2" v-for="(parkingSchedule, parkingIndex) in getOnlyReservedParkings(parking[parkingSelected[index]].schedule)" :key="'parkingSchedule-'+parkingIndex">
                  <div class="flex items-center justify-center w-32 h-8 mb-2 font-light text-gray-700 border border-gray-200 rounded-sm cursor-pointer">
                    <p class="flex items-center justify-center h-8 select-none">{{formatInterval(parkingSchedule)}}</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row-reverse justify-between w-full pt-12 pb-10 md:px-16 md:pt-20">
      <button v-if="canContinue" class="flex items-center justify-center w-40 h-12 rounded focus:outline-none md:w-54 bg-prontogreen-600" @click.prevent="makeReservationButton()">
        <p class="text-lg font-semibold text-white">Finalizar</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white/>
        </div>
      </button>

      <button v-else class="flex items-center justify-center w-40 h-12 bg-gray-300 rounded cursor-default focus:outline-none md:w-54" disabled>
        <p class="text-lg font-semibold text-white">No ha seleccionado reservas</p>
      </button>

      <button class="flex items-center justify-center w-40 h-12 md:w-54 focus:outline-none hover:bg-gray-50" @click.prevent="goBack()">
        <div class="w-5 h-5 mr-6">
          <IconComponent prefix="arrow_left"/>
        </div>
        <p class="font-medium text-gray-700">Paso previo</p>
      </button>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconComponent from '@/components/IconComponent.vue'
import moment from 'moment'
import {groupSchedules, groupToItemsSchedule} from '@/helpers';

export default {
  data() {
    return {
      title: 'Resumen de tu reserva',
      reserving: false,
      modal: {
        enabled: false,
        show: false,
        hideModal: true,
        index: 0,
        selected: [],
        isParking: false,
        groupModalSchedule: [],
      },
    }
  },

  components: {
    IconComponent
  },

  created() {
    moment().format();
  },

  computed: {
    ...mapGetters({
      centers: 'centers/centers',
      centerSelected: 'centers/selected',
      day: 'reserve/humanizeDay',
      step: 'reserve/step',
      spaces: 'schedule/spaces',
      spacesSelected: 'schedule/selected',
      parkings: 'parkings/parkings',
      parkingSelected: 'parkings/selected',
      isEnabledParking: 'reserve/isEnabledParking',
      spacesReserve: 'reserve/spaces',
      coffeeBreak: 'schedule/coffeeBreak',
    }),

    canDelete() {
      return this.modal.selected.filter(v => v).length > 0
    },

    canContinue() {
      return (Object.keys(this.spacesSelected).length + this.parkingSelected.length)
    }
  },

  methods: {
    ...mapActions({
      setStep: 'reserve/setStep',
      makeReservation: 'reserve/makeReservation',
      removeBlock: 'reserve/removeBlock',
    }),

    async makeReservationButton() {
      await this.makeReservation();
      this.setStep(this.step + 1);
    },

    getOnlyReservedSpaces(schedules) {
      return groupSchedules(schedules.filter(o => o.reserved));
    },

    getOnlyReservedParkings(parkingSchedules) {
      return groupSchedules(parkingSchedules.filter(o => o.reserved));
    },

    formatInterval(schedule) {
      return schedule['start_time'] + ' - ' + schedule['end_time'];
    },

    goBack() {
      this.setStep(this.step - 2);
    },

    getQuantity(index) {
      let space = this.spacesReserve.find(o => o.id == index)
      if (!space || space.configuration.text != 'Cantidad') {
        return 0
      }
      return configuration.value
    },

    hideModal(divIndex) {
      if (divIndex == 1) {
        if (this.modal.hideModal) {
          this.$set(this.modal, 'index', 0);
          this.$set(this.modal, 'show', false);
        }
        else {
          this.$set(this.modal, 'hideModal', true);
        }
      }
      else {
        this.$set(this.modal, 'hideModal', false);
      }
    },

    openModal(index, isParking) {
      this.modal = Object.assign({}, this.modal, {
        index : index,
        // index2: index2,
        isParking : isParking,
        show : true,
      })
      let schedules = this.groupModalSchedule()
      this.$set(this.modal, 'groupModalSchedule', schedules)
      this.$set(this.modal, 'selected', new Array(this.modal.groupModalSchedule.length).fill(0))
    },

    groupModalSchedule() {
      // if (!this.modal.index) return []
      if (this.modal.isParking) {
        return this.getOnlyReservedParkings(this.parkings[this.modal.index][this.parkingSelected[this.modal.index]].schedule)
      }
      return this.getOnlyReservedSpaces(this.spaces[this.modal.index][this.spacesSelected[this.modal.index]].schedule)
    },

    selectItemModal(index) {
      this.$set(this.modal.selected, index, !this.modal.selected[index])
    },

    submitDelete() {
      let blockSelected = this.modal.groupModalSchedule.filter((v,k) => {
        return this.modal.selected[k]
      })

      let schedules = groupToItemsSchedule(blockSelected)

      let isParking = this.modal.isParking
      let index = this.modal.index
      let index2 = isParking ? this.parkingSelected[this.modal.index] :  this.spacesSelected[this.modal.index]
      this.removeBlock({index, index2, schedules, isParking})
      this.hideModal(1)
    }

  }
}
</script>
