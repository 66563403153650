<template>
  <div class="w-full py-4 md:bg-gray-100">
    <div class="">
      <div class="py-4">
        <div class="flex justify-center">
          <p class="h-full text-lg font-semibold text-center text-gray-800 md:h-8 md:text-2xl">Estos son los horarios
            disponibles
          </p>
          <HelpComponent content="
          Selecciona el inicio y final del bloque que quieres.<br>
          Haga clic 2 veces en un horario para seleccionar únicamente un bloque.<br>
          Puedes eliminar un bloque intermedio haciendo clic.
        " />
        </div>
        <p class="h-10 text-sm text-center text-gray-400 md:text-base md:flex md:justify-center md:items-center">Para el
          {{ day }}</p>
      </div>

      <div v-if="isEnabledSpaces">
        <ThirdStep />
      </div>

      <div v-if="isEnabledParking">
        <div class="py-4">
          <p class="h-full font-semibold text-center text-gray-800 text-md md:h-8 md:text-lg">Estacionamientos</p>
        </div>
        <FourthStep />
      </div>
    </div>
    <div class="flex flex-row-reverse justify-between w-full pt-12 md:px-16 md:pt-20">
      <button v-if="canContinue"
        class="flex items-center justify-center w-40 h-12 rounded focus:outline-none md:w-54 bg-pronto-300"
        @click.prevent="goNext()">
        <p class="text-lg font-semibold text-white">Siguiente</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white />
        </div>
      </button>

      <button v-else
        class="flex items-center justify-center w-40 h-12 bg-gray-300 rounded cursor-default focus:outline-none md:w-54">
        <p class="text-lg font-semibold text-white">Siguiente</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white />
        </div>
      </button>
      <button class="flex items-center justify-center w-40 h-12 md:w-54 focus:outline-none hover:bg-gray-50"
        @click.prevent="goBack()">
        <div class="w-5 h-5 mr-6">
          <IconComponent prefix="arrow_left" />
        </div>
        <p class="font-medium text-gray-700">Paso previo</p>
      </button>
    </div>

    <!-- Modal  -->
    <div class="fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-50 xl:absolute" v-if="showOccupiedModal"></div>

    <div class="fixed inset-0 z-40 grid w-full h-full xl:absolute" v-if="showOccupiedModal" @click.prevent="hideModal()">
      <div class="py-6 m-auto bg-white border border-gray-200 rounded shadow-lg sm:w-full sm:mx-6 w-calendarTablet"
        @click.prevent="hideModal()">
        <div class="sm:flex sm:items-start">

          <div class="px-6">
            <p class="font-semibold text-gray-700 text-md">No existen espacios disponibles para la fecha seleccionada
            </p>
          </div>

          <div class="flex flex-row justify-center w-full px-6 mt-5">

            <div class="w-1/3 ">
              <button type="button"
                class="justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="hideModal()">
                Aceptar
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// TODO notify if a product is fully occupied

import { mapActions, mapGetters } from 'vuex'
import ThirdStep from '@/views/reserveSteps/ThirdStep.vue'
import FourthStep from '@/views/reserveSteps/FourthStep.vue'
import HelpComponent from '@/components/HelpComponent.vue'
import IconComponent from '@/components/IconComponent.vue'
export default {
  components: {
    ThirdStep,
    FourthStep,
    HelpComponent,
    IconComponent,
  },

  computed: {
    ...mapGetters({
      spaceSchedules: 'schedule/spaces',
      selectedSpaces: 'schedule/selected',
      isEnabledParking: 'reserve/isEnabledParking',
      isEnabledSpaces: 'reserve/isEnabledSpaces',
      loaded: 'schedule/loaded',
      step: 'reserve/step',
      day: 'reserve/humanizeDay',

      parkings: 'parkings/parkings',
      selectedParking: 'parkings/selected',
    }),

    canContinue() {
      return this.canContinueSpaces && this.canContinueParkings
    },

    canContinueSpaces() {
      for (let index in this.spaceSchedules) {
        let schedule = this.spaceSchedules[index][this.selectedSpaces[index]].schedule
        let hasReserved = schedule.find(hour => hour.reserved)
        if (!hasReserved) {
          return false
        }
      }

      return true
    },

    canContinueParkings() {
      for (let index in this.parkings) {
        let schedule = this.parkings[index][this.selectedParking[index]].schedule
        let hasReserved = schedule.find(hour => hour.reserved)
        if (!hasReserved) {
          return false
        }
      }
      return true
    },

    showOccupiedModal() {
      let ocuppied = true
      for (let index in this.spacesAvailability) {
        if (!this.spacesAvailability[index]) {
          ocuppied = false
          break
        }
      }
      return (!this.isEnabledParking && !this.isEnabledSpaces && (ocuppied))
    },

    spacesAvailability() {
      let spacesAvailability = {}

      for (let index in this.spaceSchedules) {
        let prod = this.spaceSchedules[index][this.selectedSpaces[index]]
        spacesAvailability[index] = prod
      }

      for (let index in this.parkings) {
        let prod = this.parkings[index][this.selectedParking[index]]
        spacesAvailability[index] = prod
      }

      return spacesAvailability
    }
  },

  methods: {
    ...mapActions({
      setStep: 'reserve/setStep',
    }),


    goBack() {
      this.setStep(this.step - 1);
    },

    goNext() {
      this.setStep(this.step + 2);
    },

    hideModal() {
      // this.showOccupiedModal = false
      this.goBack()
    }
  }
}
</script>
