<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden border sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-pronto-500">
              <tr>
                <th v-for="(key, keyIndex) in keys" :key="'tableHdr' + keyIndex" scope="col"
                  class="px-6 py-3 text-xs font-medium tracking-wider text-left text-white uppercase">
                  {{ key.name || '&nbsp;' }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(items, itemsIdx) in data" :key="'tableItm' + itemsIdx"
                :class="itemsIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td v-for="(key, keyIndex) in keys" :key="'container-' + itemsIdx + '-' + keyIndex" :class="{'flex gap-x-4': items[key.key].length > 1}"
                  class="px-2 py-4 text-sm font-medium whitespace-nowrap">
                  <template v-for="(item, itemIdx) in items[key.key]"
                    :key="'tableItemKey-'+itemsIdx+'-'+keyIndex+'-'+itemIdx">
                    <component v-if="(item.if === undefined) || item.if" :is="item.link ? 'a' : 'span'"
                      :href="item.link || ''" target="_blank"
                      v-on="item.event ? { click: () => $emit(item.event, items) } : {}">
                      <IconComponent v-if="item.icon" :prefix="item.icon" />
                      <span v-else-if="item.label" class="inline-flex px-2 text-xs font-semibold leading-5 rounded-full"
                        v-bind:class="item.class || 'bg-gray-100 text-gray-800'">
                        {{ item.label }}
                      </span>
                      <span v-else-if="item.text"
                        v-bind:class="(item.class || '') + ' ' + (keyIndex == 0 ? 'text-gray-900' : 'text-gray-500')">
                        {{ item.text }}
                      </span>
                      <span v-else-if="item.html"
                        v-bind:class="(item.class || '') + ' ' + (keyIndex == 0 ? 'text-gray-900' : 'text-gray-500')"
                        v-html="item.html">
                      </span>
                    </component>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The only true table.
 * @displayName Best Table
 */
import IconComponent from '@/components/IconComponent.vue'
export default {
  components: {
    IconComponent,
  },
  props: [
    /**
     * Data is an array of object where each item has the format
     * attribute: (item|{
      * text: string | link: string | icon: string | label: string | html: string
      * class: string
      * event: string
      * if: boolean
     * })
     */
    "data",
    /**
     * Keys is an object where each item has the format:
     *  {attribute: (name|key )}
     */
    "keys"
  ]
}
</script>
