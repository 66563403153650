<template>
  <div class="w-full h-full md:bg-gray-100">
    <div class="w-full px-6 pt-12 md:pt-12 md:px-16" v-if="loaded">
      <p class="text-lg font-semibold text-center text-gray-800 xl:mt-8 h-7 md:h-12 md:text-2xl">Qué espacios buscas
        reservar</p>
      <p class="mt-6 text-sm text-center text-gray-800 h-7 md:h-12 md:text-md">

        <template v-if="userOnlyContracts">
          Para acceder a todos los espacios, consulte con el Administrador de {{ client.razonSocial }}
        </template>

        <template v-else-if="clientSpacesOnly">
          Si prefieres utilizar los espacios disponibles de
          <b>Pronto Espacios </b>
          <span class="font-bold underline cursor-pointer" @click.prevent="toggleClientReservation"> haz click
            aquí</span>
        </template>
        <template v-else>
          Si prefieres utilizar los espacios contratados
          <span class="font-bold underline cursor-pointer" @click.prevent="toggleClientReservation"> haz click
            aquí</span>
          <p>
            Si tienes horas disponibles de contrato, estas serán descontadas.
          </p>
        </template>
      </p>
      <div class="mt-8 xl:mt-16 md:flex md:flex-wrap md:justify-between">
        <template v-for="(space, index) in spaces">
          <div :key="'spaceConf-' + index" v-if="availableSpace(space)" class="mb-3 bg-white border border-gray-200 rounded md:w-78 md:flex-shrink-0 md:mb-10"
            v-bind:class="{ 'h-12': !spaces[index].enabled }">
            <div class="flex items-center justify-between w-full h-12 px-4">
              <!-- v-bind:class="{'bg-gray-100' : space.enabled}" -->
              <div class="flex">
                <IconComponent :prefix="space.icon" />
                <p class="ml-5 text-gray-700">{{ space.name }}</p>
              </div>
              <CheckboxComponent :enabled.sync="spaceSelected[index]" @update:enabled="toggleSwitch(index)" color="pronto-300" />
            </div>
            <template v-if="space.enabled">
              <div class="w-full h-px bg-gray-200"></div>
              <div class="flex items-center justify-between w-full p-4">
                <p class="text-sm text-gray-600">{{ space.configuration.text }}</p>
                <input class="w-20 h-8 text-center text-gray-600 border border-gray-200 rounded-sm focus:outline-none"
                  type="number" v-model="spaceInputsValues[index]" @input="updateValue(index)" min="1" :max="centerIsMultilocacion ? multilocacion_max : null">
              </div>
              <p v-if="centerIsMultilocacion" class="w-full px-4 pb-4 text-xs text-gray-600">Por contrato puedes reservar hasta {{ multilocacion_max }} puestos</p>
              <template v-if="centerIsMultilocacion">
                <div class="w-full h-px bg-gray-200"></div>
                <div class="flex items-center justify-between w-full p-4">
                  <p class="text-sm text-gray-600 mr-4">Centro</p>
                  <select id="center" name="center" class="block w-full py-2 pl-3 pr-10 mt-1 text-sm border border-gray-200 rounded-sm focus:outline-none" @change="updateMultilocationCenter(index, $event)" v-model="spaces[index].configuration.centro_multilocacion_id">
                    <option :value="center.id" v-for="(center, index) in multilocacionCenters" :key="'centerMultOpt'+center.id">{{center.name}}</option>
                  </select>
                </div>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="flex-row-reverse w-full px-16 pt-12 pb-6 md:flex md:justify-between xl:pt-20">
      <button v-if="canContinue"
        class="flex items-center justify-center w-full h-12 rounded focus:outline-none md:w-54 bg-pronto-300"
        @click.prevent="goNext()">
        <p class="text-lg font-semibold text-white ">Siguiente</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white />
        </div>
      </button>

      <button v-else
        class="flex items-center justify-center w-full h-12 bg-gray-300 rounded cursor-default focus:outline-none md:w-54">
        <p class="text-lg font-semibold text-white">Siguiente</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white />
        </div>
      </button>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconComponent from '@/components/IconComponent.vue'
import CheckboxComponent from '@/components/CheckboxComponent.vue'
import globals from '@/store/globals.js'

export default {
  async mounted() {
    await this.clearData()
    await this.loadSpacesLocal()
  },

  components: {
    IconComponent,
    CheckboxComponent,
  },

  computed: {
    ...mapGetters({
      centers: 'centers/centers',
      centersAll: 'centers/centersAll',
      multilocacionCenters: 'centers/multilocacionCenters',
      spaces: 'reserve/spaces',
      loaded: 'reserve/loaded',
      step: 'reserve/step',
      client: 'auth/client',
      clientSpacesOnly: 'reserve/clientSpacesOnly',
      canChangeToClient: 'reserve/canChangeToClient',
      selectedCenter: 'centers/selectedCenter',
      userOnlyContracts: 'auth/user_only_contracts',
      centroMultilocacionId: 'reserve/centroMultilocacionId',
      multilocacion_max: 'reserve/multilocacion_max',
    }),

    canContinue() {
      if (this.centerIsMultilocacion && !this.centroMultilocacionId) {
        return false;
      }
      let canContinue = false;

      this.spaces.forEach(space => {
        if (space.enabled && space.configuration.value) {
          if (!this.centerIsMultilocacion) {
            canContinue = true;
          }
          if (this.centerIsMultilocacion && space.configuration.value <= this.multilocacion_max) {
            canContinue = true;
          }
        }
      });

      return canContinue;
    },
    spaceInputsValues: {
      get() {
        return this.spaces.map(sp => sp.configuration.value)
      },
    },
    spaceSelected: {
      get() {
        return this.spaces.map(sp => sp.enabled)
      }
    },

    multilocacionName() {
      return globals.multilocacion
    },

    centerIsMultilocacion() {
      return this.selectedCenter && this.selectedCenter.name == this.multilocacionName
    }
  },

  methods: {
    ...mapActions({
      loadSpaces: 'reserve/loadSpaces',
      toggleReserve: 'reserve/enable',
      setStep: 'reserve/setStep',
      setValue: 'reserve/setValue',
      clearData: 'reserve/clearAllData',
      clearEnables: 'reserve/clearEnables',
      setClientSpacesOnly: 'reserve/setClientSpacesOnly',
      setMultilocationCenter: 'reserve/setMultilocationCenter',
    }),

    updateValue(index) {
      this.setValue(index + ';' + this.spaceInputsValues[index]);
    },

    goNext() {
      this.setStep(this.step + 1)
    },

    async loadSpacesLocal() {
      await this.loadSpaces()
      await this.clearEnables()
    },

    async toggleClientReservation() {
      await this.setClientSpacesOnly(!this.clientSpacesOnly)
      await this.loadSpaces()
      await this.clearEnables()
    },

    async toggleSwitch(index) {
      await this.toggleReserve(index)
    },

    availableSpace(space) {
      if (this.selectedCenter && this.centerIsMultilocacion) {
        return space.name == globals.hotDesk
      }
      if (this.clientSpacesOnly && this.selectedCenter) {
        return space.center_id == this.selectedCenter.id
      }
      return true
    },

    updateMultilocationCenter(index, event) {
      this.setMultilocationCenter(index + ';' + event.target.value);
    }
  },

  watch: {
    spaces() {
      this.clearEnables()
    }
  }

}
</script>
