<template>
  <div class="w-full xl:flex">
    <div class="xl:w-md xl:h-230">
      <div class="w-full px-6 pt-12 md:pt-16 xl:px-12">
        <p class="h-7 font-semibold text-lg text-center text-gray-800 md:text-xl xl:text-left xl:text-3xl">{{ title }}</p>
      </div>
      <div class="w-full flex items-center justify-center px-6 mt-14 xl:hidden">
        <IconComponent prefix="alert_yellow" />
      </div>
      <div class="w-full flex items-center justify-center px-6 mt-4 xl:justify-start xl:px-12 xl:mt-24">
        <p class="font-light text-sm text-center text-gray-500 md:w-120 xl:text-left xl:leading-10 xl:text-lg">
          Recuerda que, si deseas anular esta reserva, puedes hacerlo en línea hasta 12 horas antes. De lo contrario, tendrás que hacerlo a través de <span class="font-medium text-gray-800">reservas@prontoespacios.cl</span>, con un máximo de 2 horas antes. En caso de no cumplir con estos plazos, se aplicará un cargo por la reserva.
        </p>
      </div>
      <div class="w-full px-6 mt-16 md:flex md:justify-center xl:hidden">
        <div class="w-full h-110 rounded border border-gray-200 bg-gray-50 md:w-calendarTablet">
          <object ref="pdf" width="100%" height="100%" type="application/pdf"
            :data="documentUrl + '?#zoom=85&scrollbar=0&toolbar=0&navpanes=0'">
            <embed :src="'https://docs.google.com/gview?embedded=true&url=' + documentUrl" width="100%" height="100%">
          </object>
        </div>
      </div>
      <div class="w-full px-6 mt-16 md:flex md:justify-center md:pt-20 xl:justify-start xl:px-12 xl:mt-8">
        <button class="w-full h-12 flex items-center justify-center rounded bg-pronto-600 focus:outline-none md:w-78"
          :disabled="downloading" @click.prevent="downloadFile()">
          <p class="font-semibold text-lg text-white">Descargar</p>
        </button>
      </div>
    </div>
    <div class="hidden w-full flex-grow xl:flex items-start justify-center">
      <div class="w-full h-96 rounded border border-gray-200 bg-gray-50 md:w-calendarTablet">
        <object ref="pdf" width="100%" height="100%" type="application/pdf"
          :data="documentUrl + '?#zoom=50&scrollbar=0&toolbar=0&navpanes=0'">
          <embed :src="'https://docs.google.com/gview?embedded=true&url=' + documentUrl" width="100%" height="100%">
        </object>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from '@/components/IconComponent.vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      title: 'Este es tu comprobante',
      downloading: false,
      pdfLoaded: false,
    }
  },

  components: {
    IconComponent
  },

  computed: {
    ...mapGetters({
      documentUrl: 'reserve/documentUrl',
    }),
  },

  mounted() {
    this.$refs.pdf.onload = () => this.pdfLoaded = true
    this.$refs.pdf.load = (res) => console.log(res)
  },

  methods: {
    downloadFile() {
      window.open(this.documentUrl)
    }
  }
}
</script>
