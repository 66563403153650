<template>
  <div class="w-full h-full overflow-y-scroll xl:h-screen xl:overflow-y-hidden xl:flex xl:justify-center">
    <div class="px-6 md:px-16 xl:w-160">
      <div class="py-4 md:py-6">
        <button class="h-8 md:h-12 focus:outline-none" @click.prevent="goToLogin()">
          <!-- <img class="h-8 md:h-12 xl:h-full" src="@/assets/logos/full.svg"> -->
          <div class=" bg-pronto-500 p-4 pb-2">
            <img class="h-8 md:h-12" src="@/assets/logos/Pronto_Logo H_blanco 1.png">
          </div>
        </button>
      </div>
      <p class="mt-4 text-2xl font-bold text-gray-800 md:mt-12 md:text-3xl">Recuperar contraseña</p>
      <div class="mt-12">
        <div class="mb-6 md:mb-6">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="email">Ingrese su correo electrónico</label>
          <input class="w-full h-10 px-6 mt-6 font-light text-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 " type="text" v-model="form.email">
          <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="email">{{ errors.email }}</label>
        </div>

        <div class="mt-8">
          <button class="w-full h-12 rounded-lg bg-pronto-400 focus:outline-none hover:bg-pronto-500" @click.prevent="submit">
            <p class="text-base font-semibold text-white xl:text-lg">Continuar</p>
          </button>
        </div>
      </div>
      <div class="flex justify-center mt-3 mb-16" @click="goToLogin">
        <p class="text-sm font-medium text-gray-600 cursor-pointer md:text-base hover:underline">Volver a Iniciar sesión</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPassword',
  data () {
    return {
      form: {
        email: '',
      },
      errors: {
        email: '',
      },
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      recoveryEmail: "auth/recoveryEmail",
    }),

    async submit () {
      this.recoveryEmail(this.form)
        .then(res => {
          this.$router.replace({name: 'RecoveryPasswordWaitEmail'});
        })
        .catch(err => {
          let errors = err.response.data.errors
          if (!errors.email) {
            this.errors.email = null
          } else if (Array.isArray(errors.email)) {
            this.errors.email = errors.email[0]
          } else {
            this.errors.email = errors.email
          }
        })
    },

    redirectTo(path) {
      this.$router.push({path}).catch(()=>{})
    },

    goToLogin() {
      this.$router.push({ path: '/ingresar' }).catch(()=>{})
    }
  }
}
</script>
