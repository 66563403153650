import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Reserve from '../views/Reserve.vue'
import MyReserves from '../views/MyReserves.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import RecoveryPassword from '../views/recoveryPassword/RecoveryPassword.vue'
import RecoveryPassword2 from '../views/recoveryPassword/RecoveryPassword2.vue'
import RecoveryPasswordWaitEmail from '../views/recoveryPassword/RecoveryPasswordWaitEmail.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/reservar',
    name: 'Reserve',
    component: Reserve,
    meta: { requiresAuth: true },
  },
  {
    path: '/reservas',
    name: 'MyReserves',
    component: MyReserves,
    meta: { requiresAuth: true },
  },
  {
    path: '/ingresar',
    name: 'Login',
    component: Login,
  },
  {
    path: '/registrar',
    name: 'Register',
    component: Register,
  },
  {
    path: '/recuperar-password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
  },
  {
    path: '/modificar-password',
    name: 'RecoveryPassword2',
    component: RecoveryPassword2,
  },
  {
    path: '/recuperar-password-confirmacion',
    name: 'RecoveryPasswordWaitEmail',
    component: RecoveryPasswordWaitEmail,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

export default router
