<template>
  <div>
    <label class="block text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
    <select class="block w-full py-2 pl-3 pr-10 mt-1 text-base border border-gray-500 rounded-md focus:outline-none focus:ring-pronto-500 focus:border-pronto-500 sm:text-sm"
      :value="value"
      @input="$emit('input', $event.target.value)">
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: [
    "options",
    "label",
    "value",
  ],
}
</script>
