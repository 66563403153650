<template>
  <div class="w-full h-full overflow-y-scroll xl:h-screen xl:overflow-y-hidden xl:flex xl:justify-center">
    <div class="px-6 md:px-16 xl:w-160">
      <div class="py-4 md:py-6">
        <button class="h-8 md:h-12 focus:outline-none" @click.prevent="goToLogin()">
          <!-- <img class="h-8 md:h-12 xl:h-full" src="@/assets/logos/full.svg"> -->
          <div class="">
            <img class="h-8 md:h-12" src="@/assets/logos/Pronto_Logo H_negro 1.png">
          </div>
        </button>
      </div>
      <p class="mt-4 text-2xl font-bold text-gray-800 md:mt-12 md:text-3xl">Inicia sesión</p>
      <form class="mt-12" action="#" @submit.prevent="submit">
        <div class="mb-6 md:mb-6">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="email">Correo</label>
          <input
            class="w-full h-10 px-6 mt-6 font-light text-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 "
            :disabled="!!clientList"
            :class="{'bg-gray-300 cursor-not-allowed': !!clientList}"
            type="text" v-model="form.email">
          <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="email">{{ errors.email }}</label>
        </div>
        <div class="mb-6 md:mb-6">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="password">Contraseña</label>
          <div class="relative">
            <input ref="passwordInput"
              class="w-full h-10 px-6 mt-6 font-light text-gray-600 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 "
              autocomplete="password" :type="isPasswordHidden ? 'password' : 'text'" name="password" id="password"
              v-model="form.password">
            <div class="password_toggle cursor-pointer absolute inset-y-0 right-0 flex items-center pr-5 top-6 z-10"
              @click.prevent="togglePasswordType">
              <svg v-if="isPasswordHidden" class="h-8 w-8 text-gray-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z">
                </path>
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
              </svg>
              <svg v-else class="h-8 w-8 text-gray-400" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"></path>
              </svg>
            </div>
            <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="password">{{ errors.password }}</label>

          </div>
        </div>
        <div v-if="clientList">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="cliente">Seleccione un cliente</label>
          <div class="w-full grid grid-cols-12 mt-6">
            <div class="col-span-12 md:col-span-6">
              <SelectComponent :options="clientList" v-model="form.client_id" />
              <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="client_id">{{ errors.client_id }}</label>
            </div>
            <div class="col-span-12 md:col-span-6 mt-4 md:mt-0">
              <div class="flex justify-center mt-3 mb-16" @click="clearData">
                <p class="text-sm font-medium text-gray-600 cursor-pointer md:text-base hover:underline">Seleccionar otro usuario</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6">
          <button class="w-full h-12 rounded-lg bg-pronto-400 focus:outline-none hover:bg-pronto-500" type="submit">
            <p class="text-base font-semibold text-white xl:text-lg">Ingresar</p>
          </button>
        </div>
      </form>
      <div class="flex justify-center mt-3 mb-16" @click="goToRecoveryPassword">
        <p class="text-sm font-medium text-gray-600 cursor-pointer md:text-base hover:underline">Olvidé mi contraseña</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectComponent from '@/components/SelectComponent.vue'

export default {
  name: 'Login',

  components: {
    SelectComponent,
  },

  data() {
    return {
      isPasswordHidden: true,
      form: {
        email: '',
        password: '',
        client_id: null,
      },
      errors: {
        email: '',
        password: '',
        client_id: '',
      }
    }
  },

  computed: {
    ...mapGetters({
      clientList: 'auth/clientList',
    }),
  },

  mounted() {
    this.clearData()
  },

  methods: {
    ...mapActions({
      // register: 'auth/register',
      login: 'auth/login',
      clearClientList: 'auth/clearClientList',
    }),

    async submit() {
      this.login(this.form)
        .then(res => {
          if (!this.clientList) {
            if (this.$route.query.from && this.$route.query.from != '%2Fingresar') {
              this.$router.replace(this.$route.query.from);
            } else {
              this.$router.replace({ name: 'Home' });
            }
          } else {
            this.form.client_id = this.clientList[0].value
          }
        })
        .catch(err => {
          let errors = err.response.data.errors
          this.setErrors(errors)
        })
    },

    goToLogin() {
      this.$router.push({ name: 'Login' }).catch(() => { })
    },

    goToRegister() {
      this.$router.push({ name: 'Register' }).catch(() => { })
    },

    goToRecoveryPassword() {
      this.$router.push({ name: 'RecoveryPassword' }).catch(() => { })
    },

    togglePasswordType() {
      this.isPasswordHidden = !this.isPasswordHidden;
      this.$nextTick(() => {
        this.$refs.passwordInput.focus();
      });
    },
    clearData() {
      this.clearClientList()

      this.form.email = ''
      this.form.password = ''
      this.form.client_id = null

      this.setErrors({})
    },
    setErrors(errors) {
      this.errors.email = errors.email ? errors.email[0] : ''
      this.errors.password = errors.password ? errors.password[0] : ''
      this.errors.client_id = errors.client_id ? errors.client_id[0] : ''
    }
  }
}
</script>

<style>
/* @supports (selector(::-ms-reveal)) {
  .password_toggle {
    display: none;
  }
} */
::-ms-reveal {
  display: none;
}
</style>
