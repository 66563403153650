<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <nav class="flex items-center justify-between px-4 pb-10 border-t border-gray-200 sm:px-0">
    <div class="flex flex-1 w-0 -mt-px">
      <a @click="handleClick(current_page - 1)" class="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent cursor-pointer hover:text-gray-700 hover:border-gray-300">
        <IconComponent class="w-5 h-5 mr-3 text-gray-400" aria-hidden="true" prefix="left_gray" />
        Anterior
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <template v-if="last_page <= 7">
        <a
          v-for="i in last_page" :key="i" @click="handleClick(i)"
          aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
          :class="(i == current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
        {{ i }}
        </a>
      </template>

      <template v-else>
        <template v-if="current_page <= 5">
          <a
            v-for="i in 5" :key="i" @click="handleClick(i)"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(i == current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
          {{ i }}
          </a>
          <span class="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
            ...
          </span>
          <a
            @click="handleClick(last_page)"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(last_page === current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
            {{ last_page }}
          </a>
        </template>

        <template v-else-if="(last_page - current_page) <= 5">
          <a
            @click="handleClick(1)"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(1 === current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
            1
          </a>
          <span class="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
            ...
          </span>
          <a
            @click="handleClick(i)"
            v-for="i in range(last_page - 4, last_page)" :key="i"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(i == current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
            {{ i }}
          </a>
        </template>

        <template v-else>
          <a
            @click="handleClick(1)"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(1 === current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
            1
          </a>
          <span class="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
            ...
          </span>
          <a
            @click="handleClick(i)"
            v-for="i in range((current_page - 1), (current_page + 1))" :key="i"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(i == current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
          {{ i }}
          </a>
          <span class="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
            ...
          </span>
          <a
            @click="handleClick(last_page)"
            aria-current="page" class="inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 cursor-pointer"
            :class="(last_page === current_page) ? 'border-pronto-500 text-pronto-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
            {{ last_page }}
          </a>
        </template>
      </template>
    </div>
    <div class="flex justify-end flex-1 w-0 -mt-px">
      <a @click="handleClick(current_page + 1)" class="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent cursor-pointer hover:text-gray-700 hover:border-gray-300">
        Siguiente
        <img class="w-6 h-6 ml-3 text-gray-400 fill-current" aria-hidden="true" src="@/assets/icons/right_gray.svg"/>
        <!-- <IconComponent class="w-5 h-5 ml-3 text-gray-400 fill-current" aria-hidden="true" prefix="right_gray" /> -->
      </a>
    </div>
  </nav>
</template>

<script>
import IconComponent from '@/components/IconComponent.vue'
import { range } from '@/helpers'

export default {
  props: {
    data: Object,
    onClick: Function,
  },

  computed: {
    current_page: vm => !!vm.data ? vm.data.current_page : 0,
    last_page: vm => !!vm.data ? vm.data.last_page : 0,
    per_page: vm => !!vm.data ? vm.data.per_page : 0,
    total: vm => !!vm.data ? vm.data.total : 0,
  },

  components: {
    IconComponent,
  },

  methods: {
    handleClick(page) {
      if (page == this.current_page) {
        return
      }
      if (page > this.last_page) {
        return
      }
      if (page < 1) {
        return
      }

      this.onClick(page)
      this.data.current_page = page
    },

    range: (from, to) => range(from, to),
  }
}
</script>
