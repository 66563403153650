<template>
  <div class="flex justify-center w-full py-4 bg-white border border-gray-200 rounded md:w-calendarTablet md:py-7">
    <div class="w-calendarMobile md:w-98">
      <input type="hidden" name="date" ref="date" value="">
      <div class="">
        <div class="flex items-center justify-between w-calendarMobile h-7 md:w-98 md:px-2">
          <div class="flex items-baseline">
            <span class="text-lg font-semibold text-gray-700 md:text-2xl">{{monthNames[month]}}</span>
            <span class="ml-2 text-lg font-semibold text-gray-400 md:text-2xl">{{year}}</span>
          </div>
          <div class="flex items-center">
            <button class="focus:outline-none" :class="{'cursor-not-allowed opacity-25': isActualMonth() }" :disabled="isActualMonth()" @click="minusMonth()">
              <img src="@/assets/icons/left_gray.svg">
            </button>
            <button class="focus:outline-none" @click="plusMonth()">
              <img src="@/assets/icons/right_gray.svg" class="text-gray-500 fill-current">
            </button>
          </div>
        </div>

        <div class="flex flex-wrap mt-3 w-calendarMobile md:w-98 md:mt-6">
          <div v-for="(day, index) in days" :key="'day' + index">
            <div class="w-10 text-xs font-medium text-center text-gray-700 md:w-14 md:text-sm">{{day}}</div>
          </div>
        </div>
        <div class="flex flex-wrap mt-2 w-calendarMobile md:w-98 md:mt-6">
          <div v-for="(blankday, blankDayIndex) in blankdays" :key="'blank' + blankDayIndex">
            <div class="w-10 h-8 md:w-14 md:h-12"></div>
          </div>
          <div v-for="(date, dateIndex) in noOfDays" :key="'date' + dateIndex">
            <div @click="getDateValue(date)" class="flex items-center justify-center w-10 h-8 text-gray-700 cursor-pointer md:w-14 md:h-12">
              <p class="flex items-center justify-center w-8 h-8 text-sm font-light rounded-full md:w-12 md:h-12"
                v-bind:class="{'bg-pronto-300 text-white' : isSelected(date), 'text-gray-300' : isBeforeToday(date) || !isAllowed(date)}">
                {{date}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import globals from '@/store/globals';
export default {
  data() {
    return {
      monthNames: globals.monthNames,
      days: globals.days,
      datepickerValue: '',
      month: '',
      year: '',
      noOfDays: [],
      blankdays: [],
    };
  },

  mounted() {
    this.initDate();
    let date = new Date()
    if (this.isBeforeToday(date.getDate())) {
      date.setDate(date.getDate() + 1)
      if (this.month < date.getMonth()) {
        this.plusMonth()
        this.getDateValue(1)
      } else {
        this.getDateValue(date.getDate())
      }
    }
    this.getNoOfDays();
  },

  methods: {
    ...mapActions({
      setDay: 'reserve/setDay'
    }),

    initDate() {
      let today = new Date();
      this.month = today.getMonth();
      this.year = today.getFullYear();
      let date = new Date(this.year, this.month, today.getDate()).toDateString();
      this.datepickerValue = date;
    },

    isToday(date) {
      const today = new Date();
      const d = new Date(this.year, this.month, date);
      return today.toDateString() === d.toDateString() ? true : false;
    },

    isBeforeToday(date) {
      let today = new Date();
      // last time
      const d = new Date(this.year, this.month, date, 18, 30, 0, 0);

      return d.getTime() < today.getTime();
    },

    isSelected(date) {
      const d = new Date(this.year, this.month, date);
      return this.datepickerValue === d.toDateString() ? true : false;
    },

    getDateValue(date) {
      if (this.isBeforeToday(date) || !this.isAllowed(date)) return
      let selectedDate = new Date(this.year, this.month, date);
      this.datepickerValue = selectedDate.toDateString();
      this.$refs.date.value = selectedDate.getFullYear() +"-"+ ('0'+ selectedDate.getMonth()).slice(-2) +"-"+ ('0' + selectedDate.getDate()).slice(-2);

      this.setDay(selectedDate)
    },

    getNoOfDays() {
      let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

      // find where to start calendar day of week
      let dayOfWeek = new Date(this.year, this.month).getDay();
      let blankdaysArray = [];
      for ( var i=1; i <= dayOfWeek; i++) {
        blankdaysArray.push(i);
      }

      let daysArray = [];
      for ( var i=1; i <= daysInMonth; i++) {
        daysArray.push(i);
      }

      this.blankdays = blankdaysArray;
      this.noOfDays = daysArray;
    },

    minusMonth() {
      this.month--
      if (this.month < 0) {
        this.month = 11
        this.year--
      }
      this.getNoOfDays()
    },

    plusMonth() {
      this.month++
      if (this.month > 11) {
        this.month = 0
        this.year++
      }
      this.getNoOfDays()
    },

    isActualMonth() {
      const today = new Date()
      return this.year == today.getFullYear() && this.month == today.getMonth()
    },

    isAllowed(date) {
      const d = new Date(this.year, this.month, date, 18, 30, 0, 0);
      return globals.daysAllowed[d.getDay()]
    },

  }
}
</script>
