<template>
<img :src="getIconPath()" :class="[{'filter brightness-0 invert' : white, 'filter grayscale' : dark}, icon_class]">
</template>

<script>
export default {
  props: {
    prefix: {required:true},
    icon_class: {default: ''},
    white: {type: Boolean, default: false},
    dark: {type:Boolean, default: false},
  },
  methods: {
    getIconPath() {
      let icons = require.context('../assets/icons', false, /\.svg$/);
      return icons('./' + this.prefix + ".svg");
    },
  }
}
</script>
