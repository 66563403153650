<template>
  <div class="w-full h-full overflow-y-scroll xl:h-screen xl:overflow-y-hidden xl:flex xl:justify-center">
    <div class="px-6 md:px-16 xl:w-160">
      <div class="py-4 md:py-6">
        <button class="h-8 md:h-12 focus:outline-none" @click.prevent="goToLogin()">
          <!-- <img class="h-8 md:h-12 xl:h-full" src="@/assets/logos/full.svg"> -->
          <div class="">
            <img class="h-8 md:h-12" src="@/assets/logos/Pronto_Logo H_negro 1.png">
          </div>
        </button>
      </div>
      <p class="mt-4 font-bold text-2xl text-gray-800 md:mt-12 md:text-3xl">Regístrate</p>
      <form class="mt-20" action="#" @submit.prevent="submit">
        <div class="">
          <label class="h-6 block text-sm font-medium text-gray-500 md:h-8 md:text-base" for="email">Correo</label>
          <input class="w-full h-10 mt-6 mb-6 px-6 font-light text-gray-700 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 md:mb-12" type="text" v-model="form.email">
        </div>
        <div class="">
          <label class="h-6 block text-sm font-medium text-gray-500 md:h-8 md:text-base" for="password">Contraseña</label>
          <input class="w-full h-10 mt-6 mb-6 px-6 font-light text-gray-600 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 md:mb-12" autocomplete="password" type="password" name="password" id="password" v-model="form.password">
        </div>
        <div class="">
          <label class="h-6 block text-sm font-medium text-gray-500 md:h-8 md:text-base" for="password">Confirma tu contraseña</label>
          <input class="w-full h-10 mt-6 mb-6 px-6 font-light text-gray-600 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 md:mb-12" autocomplete="password" type="password" name="confirmation" id="confirmation" v-model="form.confirmation">
        </div>
        <div class="mt-12">
          <button class="w-full h-12 rounded-lg bg-pronto-400 focus:outline-none hover:bg-pronto-500" type="submit">
            <p class="font-semibold text-base xl:text-lg text-white">Registrar</p>
          </button>
        </div>
      </form>
      <div class="flex justify-center mt-12 md:mt-16">
        <p class="font-light text-sm text-gray-600 md:text-base">Ya tienes cuenta? <span class="font-medium cursor-pointer hover:underline" @click.prevent="goToLogin()">Ingresa</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Register',
  data () {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        confirmation: '',
      }
    }
  },

  methods: {
    ...mapActions({
      register: 'auth/register'
    }),

    async submit () {

    },

    goToLogin() {
      this.$router.push({ path: '/ingresar' }).catch(()=>{})
    }
  }
}
</script>
