<template>
  <div class="reserve">
    <NavbarComponent/>
    <div class="flex flex-col items-center w-full h-16 px-6 justify-evenly md:justify-between md:h-20 md:flex-row md:px-16 xl:px-12 bg-pronto-500">
      <p class="text-xl font-semibold text-white md:text-2xl">
        Reservar
      </p>
    </div>
    <div class="w-full xl:hidden">
      <div class="w-full md:hidden" v-if="reserveStep < 5">
        <ImageComponent :height="37" :image="centers[centerIndex].prefix" v-if="centers.length"/>
      </div>
      <div class="hidden md:flex">
        <div class="w-1/2">
          <ImageComponent class="w-full h-full" :image="centers[centerIndex].prefix" v-if="centers.length"/>
        </div>
        <div class="flex flex-col w-1/2 bg-gray-200 h-72">
          <!-- Selección centro (md) -->
          <div class="flex flex-col w-full px-4 py-4 ">
            <div class="py-4">
              <label for="center" class="block text-sm font-medium text-pronto-500">Reservar en espacios</label>
            </div>
            <div class="flex flex-row justify-start gap-10">
              <button @click.prevent="toggleClientReservation(true)"
                type="button"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none  text-gray-700 bg-white hover:bg-gray-50"
                :class="{'border-prontogreen-500 bg-prontogreen-100 text-prontogreen-500': clientSpacesOnly}">
                Contratados
              </button>
              <button @click.prevent="toggleClientReservation(false)"
                type="button"
                :disabled="userOnlyContracts"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none  text-gray-700 "
                :class="{'border-prontogreen-500 bg-prontogreen-100 text-prontogreen-500': !clientSpacesOnly,
                'bg-gray-300 hover:bg-gray-300': userOnlyContracts,
                'bg-white hover:bg-gray-50': !userOnlyContracts}">
                Adicionales

                <HelpComponent v-if="userOnlyContracts" content="Sólo puedes reservar servicios ya contratados por el cliente"/>
              </button>
            </div>
            <div class="pt-4 pb-2">
              <label for="center" class="block text-sm font-medium text-pronto-500">Buscar en</label>
            </div>
            <div class="">
              <select id="center" name="center" class="block w-full py-2 pl-3 pr-10 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none" @change="selectCenter" v-model="centerSelected" :disabled="reserveStep != 0">
                <option :value="index" v-for="(center, index) in centers" :key="'centerOpt'+index">
                  {{center.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full xl:flex xl:items-stretch" v-if="reserveStep < 5">
      <div class="relative hidden xl:block w-98">
        <!-- Selección centro (xl) -->
        <div class="flex flex-col w-full px-10 bg-gray-200 h-50">

          <div class="py-4">
            <label for="center" class="block text-sm font-medium text-pronto-500">Reservar en espacios</label>
          </div>

          <div class="flex flex-row justify-start gap-10">
            <button @click.prevent="toggleClientReservation(true)"
              type="button"
              class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none  text-gray-700 bg-white hover:bg-gray-50"
              :class="{'border-prontogreen-500 bg-prontogreen-100 text-prontogreen-500': clientSpacesOnly}">
              Contratados
            </button>
            <button @click.prevent="toggleClientReservation(false)"
              type="button"
              :disabled="userOnlyContracts"
              class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none  text-gray-700"
              :class="{'border-prontogreen-500 bg-prontogreen-100 text-prontogreen-500': !clientSpacesOnly,
              'bg-gray-300 hover:bg-gray-300': userOnlyContracts,
              'bg-white hover:bg-gray-50': !userOnlyContracts
              }">
              Adicionales

              <HelpComponent v-if="userOnlyContracts" content="Sólo puedes reservar servicios ya contratados por el cliente"/>
            </button>
          </div>
          <div class="pt-4 pb-2">
            <label for="center" class="block text-sm font-medium text-pronto-500">Buscar en</label>
          </div>

          <div class="">
            <select :disabled="reserveStep != 0" id="center" name="center" class="block w-full py-2 pl-3 pr-10 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none" @change="selectCenter" v-model="centerSelected">
              <option :value="index" v-for="(center, index) in centers" :key="'centerOpt'+index">{{center.name}}</option>
            </select>
          </div>

        </div>
        <div class="flex flex-col justify-center w-full px-12 py-24 bg-white h-210">
          <div v-for="(item, stepIndex) in stepLineTime" :key="'stepLine'+stepIndex">
            <div class="flex items-center justify-start h-8 mb-12">
              <div class="flex items-center justify-center w-8 h-8 text-gray-300 border border-gray-300 rounded-full"
              v-bind:class="{'border-gray-700 text-gray-700': reserveStep == stepIndex, 'bg-prontogreen-500 border-prontogreen-500' : reserveStep > stepIndex}">
                <p v-if="stepIndex >= reserveStep" class="flex items-center justify-center w-8 h-8">{{stepIndex + 1}}</p>
                <img v-else src="@/assets/icons/check.svg">
              </div>
              <p class="ml-4 text-gray-300 uppercase" v-bind:class="{'text-gray-700': reserveStep == stepIndex}">{{item}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full xl:w-full xl:h-custom xl:overflow-y-scroll xl:bg-gray-50">
        <!-- Selección centro (sm) -->
          <div class="block h-full md:hidden">

          <div class="flex flex-col w-full px-4 pb-4 bg-gray-200">
            <div class="py-4">
              <label for="center" class="block text-sm font-medium text-pronto-500">Reservar en espacios</label>
            </div>
            <div class="flex flex-row justify-start gap-10">
            <button @click.prevent="toggleClientReservation(true)"
              type="button"
              class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none  text-gray-700 bg-white hover:bg-gray-50"
              :class="{'border-prontogreen-500 bg-prontogreen-100 text-prontogreen-500': clientSpacesOnly}">
              Contratados
            </button>
            <button @click.prevent="toggleClientReservation(false)"
              :disabled="userOnlyContracts"
              type="button"
              class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none  text-gray-700 "
              :class="{
                'border-prontogreen-500 bg-prontogreen-100 text-prontogreen-500': !clientSpacesOnly,
                'bg-gray-300 hover:bg-gray-300': userOnlyContracts,
                'bg-white hover:bg-gray-50': !userOnlyContracts
              }">
              Adicionales
                <HelpComponent v-if="userOnlyContracts" content="Sólo puedes reservar servicios ya contratados por el cliente"/>
            </button>

          </div>
          <div class="pt-4 pb-2">
            <label for="center" class="block text-sm font-medium text-pronto-500">Buscar en</label>
          </div>

          <div class="">
            <select :disabled="reserveStep != 0" id="center" name="center" class="block w-full py-2 pl-3 pr-10 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none" @change="selectCenter" v-model="centerSelected">
              <option :value="index" v-for="(center, index) in centers" :key="'centerOpt'+index">{{center.name}}</option>
            </select>
          </div>
        </div>
          </div>


        <div class="h-full md:bg-gray-100" v-show="loaded">
        <FirstStep v-if="reserveStep == 0"/>
        <SecondStep v-if="reserveStep == 1"/>
        <ThirdStep2 v-if="reserveStep == 2"/>
        <FifthStep v-if="reserveStep == 4"/>
        </div>
      </div>
    </div>
    <div class="relative w-full" v-else>
      <SixthStep/>
    </div>
    <!-- <div class="mt-16">
      <BottomBarComponent/>
    </div> -->
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import NavbarComponent from '@/components/NavbarComponent.vue'
import HelpComponent from '@/components/HelpComponent.vue'
import ImageComponent from '@/components/ImageComponent.vue'
import BottomBarComponent from '@/components/BottomBarComponent.vue'
import IconComponent from '@/components/IconComponent.vue'
import FirstStep from './reserveSteps/FirstStep.vue'
import SecondStep from './reserveSteps/SecondStep.vue'
import ThirdStep from './reserveSteps/ThirdStep.vue'
import ThirdStep2 from './reserveSteps/ThirdStep2.vue'
import FourthStep from './reserveSteps/FourthStep.vue'
import FifthStep from './reserveSteps/FifthStep.vue'
import SixthStep from './reserveSteps/SixthStep.vue'

export default {
  computed: {
    ...mapGetters({
    })
  },

  data() {
    return {
      isDropdownCollapsed: true,
      stepLineTime: [
        'Selecciona tus espacios',
        'Seleccion del dia',
        'Seleccion de horarios',
        // 'Estacionamiento',
        'Finalizar',
      ],
      // centerSelected: 0,
    }
  },

  mounted() {
    this.setStep(0);
    this.loadCenters();
  },

  components: {
    NavbarComponent,
    ImageComponent,
    BottomBarComponent,
    IconComponent,
    HelpComponent,
    FirstStep,
    SecondStep,
    ThirdStep,
    ThirdStep2,
    FourthStep,
    FifthStep,
    SixthStep,
  },

  computed: {
    ...mapGetters({
      centers: 'centers/centers',
      centerIndex: 'centers/selected',
      reserveStep: 'reserve/step',
      reserveSpaces: 'reserve/spaces',
      clientName: 'auth/clientName',
      loaded: 'reserve/loaded',
      clientSpacesOnly: 'reserve/clientSpacesOnly',
      canChangeToClient: 'reserve/canChangeToClient',
      userOnlyContracts: 'auth/user_only_contracts',
    }),
    centerSelected: {
      get() {
        return this.$store.getters['centers/selected']
      },

      set(value) {
        this.isDropdownCollapsed = true

          if (this.reserveStep != 0) {
            this.setStep(0)
            this.setSelectedCenter(value)
          } else {
            this.setSelectedCenter(value)
          }
          this.clearEnables()
      }
    }
  },

  methods: {
    ...mapActions({
      setSelectedCenter: 'reserve/changeCenter',
      setStep: 'reserve/setStep',
      loadSpaces: 'reserve/loadSpaces',
      loadCenters: 'centers/loadCenters',
      setClientSpacesOnly: 'reserve/setClientSpacesOnly',
      clearEnables: 'reserve/clearEnables',
    }),

    toggleDropdown() {
      if (this.reserveStep != 0) return
      this.isDropdownCollapsed = !this.isDropdownCollapsed;
    },

    async toggleClientReservation(clientSpacesOnly) {
      if (this.userOnlyContracts) return
      if (this.clientSpacesOnly == clientSpacesOnly) return
      await this.setStep(0)
      await this.setClientSpacesOnly(clientSpacesOnly)
      await this.loadSpaces()
      // this.spaceInputsValues = new Array(this.spaces.length).fill(1)
    },

    selectCenter() {
      return
      this.setSelectedCenter(index)
      this.isDropdownCollapsed = true
    },
  },

  watch: {
    reserveStep(value) {
      this.isDropdownCollapsed = true
    },

    async centerSelected(value) {
      this.isDropdownCollapsed = true

      if (this.reserveStep != 0) {
        await this.setStep(0)
        // this.centerSelected = value
        await this.setSelectedCenter(value)
      } else {
        await this.setSelectedCenter(value)
      }
      await this.clearEnables()
    }
  }
}
</script>
