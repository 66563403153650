import axios from '@/axios';

export default {
  namespaced: true,

  state: {
    centers: [],
    centersAll: [],
    loaded: true,
    selected: null,
    multilocacionCenters: [],
  },

  getters: {
    centers(state) {
      return state.centers;
    },

    centersAll(state) {
      return state.centersAll;
    },

    multilocacionCenters(state) {
      return state.multilocacionCenters;
    },


    loaded(state) {
      return state.loaded;
    },

    selected(state) {
      return state.selected;
    },

    selectedCenter(state) {
      return state.centers[state.selected]
    }
  },

  mutations: {
    SET_CENTERS(state, value) {
      state.centers = value;
    },

    SET_CENTERS_ALL(state, value) {
      state.centersAll = value;
    },

    SET_MULTILOCACION_CENTERS(state, value) {
      state.multilocacionCenters = value;
    },

    SET_LOADED(state, value) {
      state.loaded = value;
    },

    SET_SELECTED(state, value) {
      state.selected = value;
    },
  },

  actions: {
    loadCenters({ commit }) {
      commit('SET_LOADED', false)
      return axios.get('/centers/all').then( response => {
        commit('SET_CENTERS', response.data)
        commit('SET_CENTERS_ALL', response.data)
        commit('SET_SELECTED', 0)
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => commit('SET_LOADED', true))
    },

    loadClientCenters({ commit }) {
      commit('SET_LOADED', false)
      return axios.get('/centers/client').then( response => {
        commit('SET_CENTERS', response.data)
        commit('SET_SELECTED', 0)
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => commit('SET_LOADED', true))
    },

    loadMultilocacionCenters({ commit }) {
      commit('SET_LOADED', false)
      return axios.get('/centers/multilocacion').then( response => {
        commit('SET_MULTILOCACION_CENTERS', response.data)
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => commit('SET_LOADED', true))
    },


    setSelected({ commit }, index) {
      commit('SET_SELECTED', index);
    },
  }
}
