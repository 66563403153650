import axios from '@/axios';
import { isString } from '@/helpers';

export default {
  namespaced: true,

  state: {
    spaces: {},
    loaded: true,
    selected: {},
    coffeeBreak: {},
  },

  getters: {
    spaces(state) {
      return state.spaces;
    },

    loaded(state) {
      return state.loaded;
    },

    selected(state) {
      return state.selected;
    },

    coffeeBreak(state) {
      return state.coffeeBreak;
    },

    // custom getters

    schedulesSelected(state) {
      let scheduleReserved = {}
      for (let spaceIndex in state.selected) {
        let prodIndex = state.selected[spaceIndex]
        let product = state.spaces[spaceIndex][prodIndex]
        let schedule = product.schedule.filter((o) => o.reserved)
        let coffeeBreak = state.coffeeBreak[spaceIndex]
        scheduleReserved[spaceIndex] = {
          product_id: product.id,
          schedule,
          coffeeBreak,
        }
      }
      return scheduleReserved
    },

    selectedMap(state) {
      let res = Object.keys(state.selected).reduce((result, key) => {
        if (isString(key)) {
          result[key] = parseInt(key.split('-')[0])
        } else {
          result[key] = key
        }
        return result;
      }, {})
      return res
    }
  },

  mutations: {
    SET_SPACES(state, value) {
      state.spaces = value;
    },

    SET_LOADED(state, value) {
      state.loaded = value;
    },

    SET_SELECTED(state, value) {
      state.selected = value;
    },

    SET_RESERVED(state, { space, spaceIndex, scheduleIndex }) {
      let index = [space, spaceIndex, scheduleIndex];
      let localSchedule = state.spaces[index[0]][index[1]].schedule[index[2]];
      if (localSchedule.available) {
        localSchedule.reserved = !localSchedule.reserved;
        state.spaces[index[0]][index[1]].schedule[index[2]] = localSchedule;
      }
    },

    SET_COFFEE_BREAK(state, { index, value }) {
      state.coffeeBreak[index] = value;
      // force vue reactivity
      state.coffeeBreak = { ...state.coffeeBreak };
    },

    SET_COFFEE_BREAK_ALL(state, value) {
      state.coffeeBreak = value;
    },

    CLEAR_RESERVED(state, index) {
      let space = state.selected[index]
      let spaceSchedule = state.spaces[index][space]
      spaceSchedule.schedule.forEach((item, key) => {
        state.spaces[index][space].schedule[key].reserved = false
      })
    }

  },

  actions: {
    async loadSpaceSchedules({ commit, rootGetters }) {
      if (Object.keys(rootGetters['schedule/spaces']).length) {
        return;
      }

      commit('SET_LOADED', false)

      let day = rootGetters['reserve/systemDay']
      let center_id = rootGetters['centers/selectedCenter'].id
      let enabled_spaces = rootGetters['reserve/enabledSpaces']
      let spaces = rootGetters['reserve/spaces']
      let clientSpacesOnly = rootGetters['reserve/clientSpacesOnly']
      let client = rootGetters['auth/client']
      let centroMultilocacionId = rootGetters['reserve/centroMultilocacionId']

      let capacity = []
      spaces.forEach(s => {
        if (s.enabled) {
          capacity.push({
            id: s.id,
            unidad: s.configuration.text,
            cantidad: s.configuration.value
          })
        }
      })

      await axios.get('/schedules', {
        params: {
          fecha: day,
          centro_id: center_id,
          clasificaciones_id: enabled_spaces,
          solo_cliente: clientSpacesOnly ? 1 : 0,
          capacidad: capacity,
          cliente_id: client.id,
          centro_multilocacion_id: centroMultilocacionId,
        }
      }).then(response => {
        let spaceData = response.data

        let selectedData = Object.keys(spaceData).reduce((result, key) => {
          let spaceType = spaces.find(s => s.id == key)
          if (spaceType.configuration.text == 'Cantidad') {
            for (let i = 0; i < spaceType.configuration.value; i++) {
              let fullKey = `${key}-${i}`
              result[fullKey] = i
              spaceData[fullKey] = [...spaceData[key]]
            }
            delete spaceData[key]
          } else {
            result[key] = 0;
          }
          return result;
        }, {})

        commit('schedule/SET_SPACES', spaceData, { root: true })
        commit('SET_SELECTED', selectedData)
        commit('SET_LOADED', true)

      }).catch(error => {
        console.log(error)
      });
    },

    setReserved({ commit }, value) {
      commit('SET_RESERVED', value);
    },

    setSelected({ commit }, value) {
      commit('SET_SELECTED', value);
    },

    setCoffeeBreak({ commit, rootGetters }, { index, value }) {
      commit('SET_COFFEE_BREAK', { index, value })
    },

    clearReserved({ commit }, index) {
      commit('CLEAR_RESERVED', index);
    }
  }
}
