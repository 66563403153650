import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/tailwind.css'
import './css/fonts.css'
import './css/scrollbar.css'
import './css/tooltip.css'
import VTooltip from 'v-tooltip'

Vue.config.productionTip = false

Vue.use(VTooltip)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
