var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
  content: _vm.content,
  delay: {
    show: 100,
    hide: 300,
    classes: ['w-30'],
  },
  trigger: 'hover focus click'
}),expression:"{\n  content: content,\n  delay: {\n    show: 100,\n    hide: 300,\n    classes: ['w-30'],\n  },\n  trigger: 'hover focus click'\n}"}],class:_vm.icon_class,attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])}
var staticRenderFns = []

export { render, staticRenderFns }