<template>
  <div class="w-full h-full overflow-y-scroll xl:h-screen xl:overflow-y-hidden xl:flex xl:justify-center">
    <div class="px-6 md:px-16 xl:w-160">
      <div class="py-4 md:py-6">
        <button class="h-8 md:h-12 focus:outline-none" @click.prevent="goToLogin()">
          <!-- <img class="h-8 md:h-12 xl:h-full" src="@/assets/logos/full.svg"> -->
          <div class="">
            <img class="h-8 md:h-12" src="@/assets/logos/Pronto_Logo H_negro 1.png">
          </div>
        </button>
      </div>
      <p class="mt-4 text-2xl font-bold text-gray-800 md:mt-12 md:text-3xl">Modificar contraseña</p>
      <p class="mt-4 text-md md:mt-12 md:text-md text-gray-700">Ingrese una nueva contraseña con un mínimo de 8 caracteres</p>
      <form class="mt-12" action="#" @submit.prevent="submit">
        <div class="mb-6 md:mb-6">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="email">Correo</label>
          <input class="w-full h-10 px-6 mt-6 font-light text-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 " type="text" :value="form.email" disabled>
          <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="email">{{ errors.email }}</label>

        </div>

        <div class="mb-6 md:mb-6">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="password">Nueva contraseña</label>
          <input class="w-full h-10 px-6 mt-6 font-light text-gray-600 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 " autocomplete="password" type="password" name="password" id="password" v-model="form.password">
          <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="password">{{ errors.password }}</label>
        </div>

        <div class="mb-6 md:mb-6">
          <label class="block h-6 text-sm font-medium text-gray-500 md:h-8 md:text-base" for="password_confirmation">Confirme contraseña</label>
          <input class="w-full h-10 px-6 mt-6 font-light text-gray-600 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-pronto-200 focus:border-transparent md:h-12 " autocomplete="password" type="password" name="password_confirmation" id="password_confirmation" v-model="form.password_confirmation">
          <label class="block h-6 mt-4 text-sm font-medium text-red-600" for="password_confirmation">{{ errors.password_confirmation }}</label>
        </div>
        <div class="mt-8">
          <button class="w-full h-12 rounded-lg bg-pronto-400 focus:outline-none hover:bg-pronto-500" type="submit">
            <p class="text-base font-semibold text-white xl:text-lg">Continuar</p>
          </button>
        </div>
      </form>
      <div class="flex justify-center mt-3 mb-16" @click="goToLogin">
        <p class="text-sm font-medium text-gray-600 cursor-pointer md:text-base hover:underline">Volver a Iniciar sesión</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPassword2',
  data () {
    return {
      form: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      errors: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  created() {
    this.form.token = this.$route.query.token
    this.form.email = this.$route.query.email
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      confirmPassword: "auth/confirmPassword",
    }),

    async submit () {
      this.confirmPassword(this.form)
      .then(res => {
        this.$router.replace({name: 'Login'});
      })
      .catch(err => {
        let errors = err.response.data.errors
        this.errors.email = errors.email ? errors.email[0] : ''
        this.errors.password = errors.password ? errors.password[0] : ''
        this.errors.password_confirmation = errors.password_confirmation ? errors.password_confirmation[0] : ''
      })
    },

    goToLogin() {
      this.$router.push({ name: 'Login' }).catch(()=>{})
    }
  }
}
</script>
