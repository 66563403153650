<template>
  <div class="w-full">
    <div class="flex items-center justify-between w-full px-6 py-4 md:py-6 md:px-16 xl:px-12 mb-2">
      <button class="h-6 md:h-8 focus:outline-none" @click.prevent="redirectTo('/')">
          <!-- <img class="h-8 md:h-12 xl:h-full" src="@/assets/logos/full.svg"> -->
          <div class="">
            <img class="h-8 md:h-12" src="@/assets/logos/Pronto_Logo H_negro 1.png">
          </div>
        </button>
      <button class="focus:outline-none md:hidden" @click.prevent="toggleMenu()">
        <img src="@/assets/icons/x.svg" v-if="this.isMenuOpen">
        <img src="@/assets/icons/menu.svg" v-else>
      </button>
      <div class="items-center hidden md:flex">
        <button class="mr-12 focus:outline-none" @click.prevent="redirectTo('/reservar')">
          <span class="text-sm text-gray-900 xl:text-lg font-medium">Reservar</span>
        </button>
        <button class="mr-12 focus:outline-none" @click.prevent="redirectTo('/reservas')">
          <span class="text-sm text-gray-900 xl:text-lg font-medium">Historial de reservas</span>
        </button>
        <div class="relative">
          <div :class="{'z-50' : isMenuOpen}">
            <button class="flex items-center justify-center rounded-full focus:outline-none w-9 h-9 bg-pronto-300" @click="toggleMenu">
              <span class="sr-only" @click.prevent="logout()">Cerrar sesión</span>
              <span class="text-sm font-bold text-white ">{{ initials }}</span>
            </button>
          </div>
          <div v-if="isMenuOpen" class="fixed inset-0 z-40 bg-gray-900 opacity-30" aria-hidden="true" @click="toggleMenu"></div>
          <div v-if="isMenuOpen" class="absolute right-0 z-50 w-48 mt-2 overflow-hidden origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
            <div href="#" class="block px-4 py-2 text-sm text-gray-700 border-b border-gray-400" role="menuitem" tabindex="-1" id="user-menu-item-0">
              {{ user.nombre }} {{ user.apellido }}
              <br>
              <!-- {{ user_admin }} -->
              <!-- {{ client }} -->
              <span v-if="user_admin && client" class="text-xs">
                Administrador {{ client.razonSocial }}
              </span>
              <span v-else-if="!user_admin && client" class="text-xs">
                Usuario {{ client.razonSocial }}
              </span>

            </div>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-1" @click="logout()">Cerrar sesión</a>
          </div>
        </div>
        <!-- <div class="flex items-center justify-center focus:outline-none w-9 h-9 xl:hidden" @click.prevent="logout()">
          <span class="text-sm text-gray-700 xl:text-lg ">Cerrar sesión</span>
        </div> -->
      </div>
    </div>
    <div class="w-full px-6 py-3 bg-gray-100 md:hidden" v-if="isMenuOpen">
      <div class="flex items-center justify-center w-full h-8 cursor-pointer" @click.prevent="redirectTo('/reservar')">
        <span class="text-sm text-gray-700">Reservar</span>
      </div>
      <div class="h-2"></div>
      <div class="flex items-center justify-center w-full h-8 cursor-pointer" @click.prevent="redirectTo('/reservas')">
        <span class="text-sm text-gray-700">Historial de reservas</span>
      </div>
      <div class="h-2"></div>
      <div class="flex items-center justify-center w-full h-8 cursor-pointer" @click.prevent="logout()">
        <span class="text-sm text-gray-700">Cerrar sesión</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      initials: 'auth/initials',
      user: 'auth/user',
      client: 'auth/client',
      user_admin: 'auth/user_admin',
    })

  },
  data() {
    return {
      isMenuOpen: false
    }
  },

  methods: {
    ...mapActions({
      logoutUser: 'auth/logout',
      setStep: 'reserve/setStep',
    }),

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    logout() {
      this.$router.push({name: 'Login'})

      this.logoutUser().then(() => this.$router.push({name: 'Login'}))
    },

    redirectTo(path) {
      if (this.$router.currentRoute.path == path) {
        this.setStep(0)
      } else {
        this.$router.push(path)
      }
    }
  },

}
</script>
