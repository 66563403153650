import axios from '@/axios';
import {cloneObject} from '@/helpers';

export default {
  namespaced: true,

  state: {
    parkings: [],
    loaded: true,
    selected: []
  },

  getters: {
    parkings(state) {
      return state.parkings;
    },

    loaded(state) {
      return state.loaded;
    },

    selected(state) {
      return state.selected;
    },

    // custom getters

    parkingSelected(state) {
      if (Object.keys(state.parkings).length == 0) {
        return {}
      }
      let scheduleReserved = {4: []}
      for (let parkingIndex in state.selected) {
        let product = state.parkings[parkingIndex][state.selected[parkingIndex]]
        let schedule = product.schedule.filter((o) => o.reserved)
        scheduleReserved[4].push({
          product_id: product.id,
          schedule: schedule,
        })
      }

      return scheduleReserved
    },

  },

  mutations: {
    SET_PARKINGS(state, value) {
      state.parkings = value;
    },

    SET_LOADED(state, value) {
      state.loaded = value;
    },

    SET_SELECTED(state, value) {
      state.selected = value;
    },

    SET_RESERVED(state, {value, index, selected}) {
      let parking = state.parkings[index][selected]
      let localSchedule = parking.schedule[value];
      if (localSchedule.available) {
        localSchedule.reserved = !localSchedule.reserved;
        parking.schedule[value] = localSchedule;
      }
    },

    CLEAR_RESERVED(state, index) {
      let parking = state.selected[index]
      let parkingSchedule = state.parkings[index][parking]
      parkingSchedule.schedule.forEach((item, key) => {
        state.parkings[index][parking].schedule[key].reserved = false
      })
    }
  },

  actions: {
    async loadParkings({ commit, rootGetters }) {
      if (Object.keys(rootGetters['parkings/parkings']).length) {
        return;
      }
      commit('SET_LOADED', false)

      let parkings = rootGetters['parkings/parkings']
      let day = rootGetters['reserve/systemDay']
      let center_id = rootGetters['centers/selectedCenter'].id
      let enabled_spaces = rootGetters['reserve/parkingId']
      let parkingCount = rootGetters['reserve/parkingCount']
      let clientSpacesOnly = rootGetters['reserve/clientSpacesOnly']

      await axios.get('/schedules', {
        params: {
          fecha: day,
          centro_id: center_id,
          clasificaciones_id: [enabled_spaces],
          solo_cliente: clientSpacesOnly ? 1 : 0,
        }
      }).then(response => {
        let spaceData = response.data[enabled_spaces]
        let selectedData = [...Array(parkingCount).keys()]
        let parkingsData = selectedData.map((_) => cloneObject(spaceData))

        commit('SET_PARKINGS', parkingsData)
        commit('SET_SELECTED', selectedData)
        commit('SET_LOADED', true)
      }).catch(error => {
        console.log(error)
      });
    },

    setReserved({ commit }, data) {
      commit('SET_RESERVED', data);
    },

    setSelected({ commit }, value) {
      commit('SET_SELECTED', value);
    },

    clearReserved({ commit }, index) {
      commit('CLEAR_RESERVED', index);
    }
  }
}
