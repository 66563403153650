<template>
  <div class="w-full pt-16 xl:pt-24" v-if="hasReserves">
    <div class="flex flex-col items-center justify-center">
      <div class="flex items-center justify-center w-full h-16 px-6 mb-8 md:px-16 md:justify-start xl:justify-center">
        <p class="text-xl font-semibold text-gray-900 md:text-2xl xl:text-32">Mis reservas</p>
      </div>
      <div class="w-full px-6 select-none md:flex md:justify-between md:px-16 md:flex-wrap xl:w-xl xl:px-0 xl:flex-none xl:flex-nowrap">
        <div class="relative w-full mb-12 cursor-pointer h-50 md:w-76 md:h-45 xl:w-80 xl:flex-shrink-0 xl:mr-10" v-for="(reserve, index) in myReserves" :key="'my-reserve-'+index" @click.prevent="goToReserve(reserve)">
          <ImageComponent :height="50" :image="reserves[index].imagen"/>
          <!-- <div class="absolute top-0 left-0 mt-6 md:mt-0">
            <div class="flex flex-col items-start justify-center text-left w-44 min-h-10 bg-gradient-to-r from-prontogreen-800 to-prontogreen-400">
              <p class="ml-5 text-sm font-medium text-white">{{ reserve.reserva }}</p>
            </div>
          </div> -->

          <div class="absolute bottom-0 right-0 mb-6 md:mb-0">
            <div class="flex flex-col items-start justify-start w-40 h-10 text-left bg-gradient-to-r from-pronto-800 to-pronto-600">
              <p class="ml-5 text-sm font-medium text-white">{{ reserve.fecha }}</p>
              <p class="ml-5 text-sm font-medium text-white">{{ reserve.horario }}</p>
            </div>
          </div>
          <div class="font-semibold text-center text-gray-900">
            <p>{{ reserve.reserva }}</p>
            <p>{{ reserve.centro }}</p>
          </div>

        </div>
      </div>
      <div class="flex justify-center w-full mt-10" v-if="hasMoreReserves" @click.prevent="goToReserves()">
        <button class="text-pronto-600">Ver más reservas</button>
      </div>
    </div>
    <!-- <MyReserveModalComponent
    :show-modal.sync="showModal"
    :reserve-url="reserveUrl"
    /> -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ImageComponent from '@/components/ImageComponent.vue'
// import MyReserveModalComponent from '@/components/MyReserveModalComponent.vue'

export default {
  components: {
    ImageComponent,
    // MyReserveModalComponent,
  },

  data() {
    return {
      homeListSize: 4,
      showModal: false,
      reserveUrl: '',
    }
  },

  created() {
    this.loadHomeReserves();
  },

  computed: {
    ...mapGetters({
      reserves: 'myReserves/homeReserves',
    }),

    hasReserves() {
      return this.reserves && this.reserves.length > 0
    },

    myReserves() {
      return this.reserves.slice(0, this.homeListSize)
    },

    hasMoreReserves() {
      return this.reserves.length > this.homeListSize
    },
  },

  methods: {
    ...mapActions({
      loadHomeReserves: 'myReserves/loadHomeReserves',
    }),

    goToReserve(reserve) {
      this.reserveUrl = reserve.link
      window.open(this.reserveUrl)
      // this.showModal = true
    },

    goToReserves() {
      this.$router.push({ name: 'MyReserves' });
    },
  }
}
</script>
