<template>
  <div class="w-full md:bg-gray-100">
    <div class="w-full px-6 pt-12 md:pt-16">
      <p class="text-lg font-semibold text-center text-gray-800 h-7 md:text-2xl">{{title}}</p>
      <div class="flex items-center justify-center w-full mt-10">
        <DatePickerComponent/>
      </div>
    </div>
    <div class="flex flex-row-reverse justify-between w-full pt-12 pb-10 md:px-16 md:pt-20">
      <button v-if="canContinue" class="flex items-center justify-center w-40 h-12 rounded focus:outline-none md:w-54 bg-pronto-300" @click.prevent="goNext()">
        <p class="text-lg font-semibold text-white">Siguiente</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white/>
        </div>
      </button>

      <button v-else class="flex items-center justify-center w-40 h-12 bg-gray-300 rounded cursor-default focus:outline-none md:w-54">
        <p class="text-lg font-semibold text-white">Siguiente</p>
        <div class="w-5 h-5 ml-6">
          <IconComponent prefix="arrow_right" white/>
        </div>
      </button>
      <button class="flex items-center justify-center w-40 h-12 md:w-54 focus:outline-none hover:bg-gray-50" @click.prevent="goBack()">
        <div class="w-5 h-5 mr-6">
          <IconComponent prefix="arrow_left"/>
        </div>
        <p class="font-medium text-gray-700">Paso previo</p>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconComponent from '@/components/IconComponent.vue'
import CheckboxComponent from '@/components/CheckboxComponent.vue'
import BottomBarComponent from '@/components/BottomBarComponent.vue'
import DatePickerComponent from '@/components/DatePickerComponent.vue'

export default {
  data() {
    return {
      title: 'Selecciona un día',
    }
  },

  created() {
    this.clearData();
  },

  components: {
    IconComponent,
    CheckboxComponent,
    BottomBarComponent,
    DatePickerComponent,
  },

  computed: {
    ...mapGetters({
      spaces: 'reserve/spaces',
      day: 'reserve/day',
      step: 'reserve/step',
    }),

    canContinue() {
      return this.day != null;
    }
  },

  methods: {
    ...mapActions({
      setStep: 'reserve/setStep',
      clearData: 'reserve/clearReserveData',
    }),

    goBack() {
      this.setStep(this.step - 1);
    },

    goNext() {
      this.setStep(this.step + 1)
    }
  }
}
</script>
