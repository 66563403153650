<template>
  <div class="w-full home">
    <navbar-component/>
    <call-to-action-component/>
    <my-reserve-card-component/>
    <our-centers-component/>
    <bottom-bar-component/>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue'
import CallToActionComponent from '@/components/CallToActionComponent.vue'
import OurCentersComponent from '@/components/OurCentersComponent.vue'
import BottomBarComponent from '@/components/BottomBarComponent.vue'
import { mapActions } from 'vuex'
import MyReserveCardComponent from '../components/MyReserveCardComponent.vue'

export default {
  name: 'Home',

  components: {
    NavbarComponent,
    CallToActionComponent,
    OurCentersComponent,
    BottomBarComponent,
    MyReserveCardComponent,
  },

  async created() {
    await this.loadCenters()
    // await this.loadMultilocacionCenters()
  },

  methods: {
    ...mapActions({
      loadCenters: 'centers/loadCenters',
      loadMultilocacionCenters: 'centers/loadMultilocacionCenters',
    })
  }
}
</script>
